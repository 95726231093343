import { ReactElement, useEffect, useRef, useState } from 'react';

import Snapshot from 'modules/ShippingFixtures/Components/SanctionSearchSnaphot';

import type { SanctionSnapshotActivityHistory } from 'modules/ShippingFixtures/Models/SanctionSearchHistory';

import styles from './RowExpansionTemplate.module.scss';

interface RowExpansionTemplateProps {
  data: SanctionSnapshotActivityHistory;
}

export function RowExpansionTemplate(props: RowExpansionTemplateProps): ReactElement {
  const { data } = props;
  const loaderbar = useRef<HTMLElement>(null);
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    if (!loaderbar.current) { return; }
    setReady(true);
  }, [loaderbar]);

  return <>
    <div className={styles.container}>
      <span className={styles.loader} ref={loaderbar} />
      {ready && <Snapshot
        className={styles.snapshot}
        fixtureid={null}
        snapshotid={data.id}
        target={loaderbar.current!}
        showToggler={false}
      />}
    </div>
  </>;
}

export default RowExpansionTemplate;