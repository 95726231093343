import {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { Options, SVGElement } from 'highcharts';
import * as Highcharts from 'highcharts';
import exportingModule from 'highcharts/modules/exporting';
import HighchartsReact, {
  HighchartsReactRefObject,
} from 'highcharts-react-official';
import { DateTime } from 'luxon';
import { Dropdown, type DropdownChangeEvent } from 'primereact/dropdown';

import ChartActionsMenu from 'components/ChartActionsMenu';
import { ChartingDisplayState } from 'components/ColumnLayoutSelector';
import { ToastMessageRef } from 'components/ToastMessage';

import { CloseTimeOptions } from '../../Models/Enums';

import { EnumKeys, getValueCollection } from 'helpers/Utils/enum';
import { getFixedValue } from 'helpers/Utils/formatters';
import { notNil } from 'helpers/Utils/misc';

import styles from './Chart.module.scss';

type ChartingProps = {
  sourceName: string;
  feed: string;
  size: ChartingDisplayState;
  series?: Record<string, Highcharts.SeriesLineOptions> | null;
  toastRef?: RefObject<ToastMessageRef>;
  selectedCloseTime?: CloseTimeOptions;
  setSelectedCloseTime?: Dispatch<SetStateAction<CloseTimeOptions>>;
  previousCloseValue?: number | null;
  isLegendHidden?: boolean;
  isPreviousClose?: boolean;
};

export type ChartReferenceProps = {
  resetRequest: () => void;
};

const DEFAULT_CHART_TYPE = 'Monthly';

const closeTimes: EnumKeys[] = getValueCollection(CloseTimeOptions, true);
let customLabel: SVGElement | undefined;
let customLabelBg: SVGElement | undefined;
let customLabelCT: SVGElement | undefined;

exportingModule(Highcharts);

const Chart = (props: ChartingProps): JSX.Element => {
  const {
    series = {},
    feed,
    sourceName,
    size,
    toastRef,
    selectedCloseTime,
    setSelectedCloseTime,
    previousCloseValue,
    isLegendHidden = false,
    isPreviousClose = false,
  } = props;

  const [selectedChart, setSelectedChart] =
    useState<string>(DEFAULT_CHART_TYPE);

  const hc = useRef<HighchartsReactRefObject>(null);

  const [options, setOptions] = useState<Options | null>();

  const getExportFileName = useCallback(
    () =>
      `${ feed.toUpperCase() } - ${ sourceName } - ${ DateTime.now().toFormat(
        'yyyy-dd-MM HH:mm'
      ) }`,
    [feed, sourceName]
  );

  const periods = Object.keys(series || {});

  const selectedSeries = useMemo(
    () => (series ? series[selectedChart] : undefined),
    [series, selectedChart]
  );

  const handleSelectCloseTime = (e: DropdownChangeEvent): void => {
    setSelectedCloseTime && setSelectedCloseTime(e.value);
  };

  useEffect(() => {
    const showPreviousClose = isPreviousClose &&
      notNil(previousCloseValue) &&
      selectedCloseTime !== CloseTimeOptions.SwitchOff;

    setOptions({
      series: selectedSeries && [selectedSeries],
      chart: {
        backgroundColor: 'transparent',
        styledMode: true,
        style: {
          fontFamily: 'OpenSans',
        },
        events: {
          render: function () {
            if (!showPreviousClose) {
              // Clear if not active
              customLabel && customLabel.destroy();
              customLabelBg && customLabelBg.destroy();
              customLabelCT && customLabelCT.destroy();
              customLabel = undefined;
              customLabelBg = undefined;
              customLabelCT = undefined;
              return;
            }

            const chart: Highcharts.Chart = this;
            const text = `PREVIOUS CLOSE: ${ getFixedValue(`${ previousCloseValue }`, 4) }`;
            const textCT = `CLOSE TIME: ${ selectedCloseTime }`;

            // Remove any existing custom label or background
            customLabel && customLabel.destroy();
            customLabelBg && customLabelBg.destroy();
            customLabelCT && customLabelCT.destroy();

            const labelStyles = {
              left: '0',
              top: '0',
              color: '#B588DC', // egg-plant-60
              fontSize: '10px',
              padding: '0',
            };

            customLabel = chart.renderer.label(text, 0, 0, undefined, undefined, undefined, true)
              .css(labelStyles)
              .attr({
                zIndex: 3,
                class: 'highcharts-custom-label'
              }).add();

            customLabelCT = chart.renderer.label(textCT, 0, 20, undefined, undefined, undefined, true)
              .css(labelStyles)
              .attr({
                zIndex: 3,
                class: 'highcharts-custom-label-ct'
              }).add();

            const bbox = customLabel.getBBox();

            customLabelBg = chart.renderer.rect(0, 0, bbox.width + 10, bbox.height, 3)
              .attr({
                zIndex: 2,
                class: 'highcharts-custom-label-bg',
                fill: '#353434', // Charcoal
              }).add();

            customLabelBg.toFront();
            customLabel.toFront();
            customLabelCT.toFront();
          }
        }
      },
      title: {
        text: undefined,
      },
      yAxis: {
        title: { text: undefined },
        plotLines: showPreviousClose && previousCloseValue !== null ? [{
          value: previousCloseValue,
          zIndex: 1, // So it's not covered by grid lines
        }] : undefined
      },
      xAxis: {
        type: 'category',
        labels: {
          step: Math.round(
            (ChartingDisplayState.Full - size) *
              ((selectedSeries?.data?.length || 0) / 10)
          ),
        },
      },
      legend: {
        enabled: !isLegendHidden
      },
      exporting: {
        enabled: false,
        fallbackToExportServer: false,
      },
    });
  }, [series, selectedChart, selectedSeries, size, isPreviousClose, previousCloseValue, selectedCloseTime, isLegendHidden]);

  return (
    <div className={styles.container}>
      <div className={clsx('form-input__container', styles.inputContainer)}>
        <label className={styles.label} htmlFor='dropdown-period'>Period</label>
        <div className={styles.actions}>
          <Dropdown
            id='dropdown-period'
            className="grow-to-fill"
            options={periods}
            value={selectedChart}
            onChange={({value}): void => setSelectedChart(value)}
          />
          <ChartActionsMenu
            fileName={getExportFileName}
            highchartRef={hc}
            isDisabled={!selectedSeries?.data?.length}
            toastRef={toastRef}
          />
        </div>
      </div>
      {isPreviousClose && <div className={clsx('form-input__container', styles.inputContainer)}>
        <label className={styles.label} htmlFor='chart__dropdown-ct'>Close Time</label>
        <div className='chart__dropdown-period--container direction--row'>
          <Dropdown
            id='chart__dropdown-ct'
            className='chart__dropdown grow-to-fill'
            panelClassName='chart__dropdown--panel grow-to-fill'
            options={closeTimes}
            optionLabel='key'
            optionValue='key'
            value={selectedCloseTime}
            onChange={handleSelectCloseTime}
          />
        </div>
      </div>}
      {options && (
        <HighchartsReact
          ref={hc}
          highcharts={Highcharts}
          options={options}
          allowChartUpdate={true}
          {...props}
        />
      )}
    </div>
  );
};

export default Chart;
