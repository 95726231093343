import {
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { HighchartsReactRefObject } from 'highcharts-react-official';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';

import { ToastMessageRef } from 'components/ToastMessage';

import { convertSvgToImg } from 'helpers/Utils/image';

import './ChartActionsMenu.scss';

enum ChartMenuOptions {
  COPY = 'COPY',
  SAVE = 'SAVE',
  EXPORT = 'EXPORT',
}

type ChartActionsMenuProps = {
  fileName: string | (() => string);
  highchartRef: RefObject<HighchartsReactRefObject>;
  isDisabled?: boolean;
  onExportClick?: () => void;
  toastRef?: RefObject<ToastMessageRef>;
};

const ChartActionsMenu = forwardRef<any, ChartActionsMenuProps>(
  (props, ref) => {
    const { fileName, highchartRef, isDisabled, onExportClick, toastRef } =
      props;

    const [chartActions, setChartActions] = useState<MenuItem[]>([]);

    const menu = useRef<Menu>(null);

    const onCopy = useCallback(() => {
      const svgContent = highchartRef.current?.chart.getSVG();

      if (svgContent) {
        convertSvgToImg(svgContent).then(data => {
          if (data) {
            navigator.clipboard.write([
              new ClipboardItem({
                'image/png': data,
              }),
            ]);
          }
          toastRef?.current?.replace({
            title: 'Chart copied to clipboard',
            message:
              'You can now paste this into your Word, Powerpoint document or Whatsapp message',
          });
        });
      }
    }, [highchartRef, toastRef]);

    const onSave = useCallback(() => {
      highchartRef.current?.chart.exportChart(
        {
          filename: typeof fileName === 'function' ? fileName() : fileName,
          type: 'image/png',
          fallbackToExportServer: false,
        },
        {}
      );
    }, [highchartRef, fileName]);

    const chartActionSelected = useCallback(
      (value: ChartMenuOptions) => {
        switch (value) {
          case ChartMenuOptions.COPY:
            onCopy();
            break;
          case ChartMenuOptions.SAVE:
            onSave();
            break;
          case ChartMenuOptions.EXPORT:
            onExportClick && onExportClick();
            break;
          default:
            break;
        }
      },
      [onCopy, onExportClick, onSave]
    );

    useEffect(() => {
      const actions: MenuItem[] = [
        {
          label: 'Copy chart to clipboard',
          data: ChartMenuOptions.COPY,
          icon: 'iconoir-multiple-pages icon--tiny',
          disabled: isDisabled,
          command: event => chartActionSelected(event.item.data),
        },
        {
          label: 'Save chart as',
          data: ChartMenuOptions.SAVE,
          icon: 'iconoir-floppy-disk icon--tiny',
          disabled: isDisabled,
          command: event => chartActionSelected(event.item.data),
        },
      ];

      if (onExportClick) {
        actions.push({
          label: 'Export data',
          data: ChartMenuOptions.EXPORT,
          icon: 'iconoir-download icon--tiny',
          disabled: isDisabled,
          command: event => chartActionSelected(event.item.data),
        });
      }

      setChartActions(actions);
    }, [isDisabled, chartActionSelected, onExportClick]);

    return (
      <div className='align--right'>
        <Button
          className='chart-dropdown-actions'
          icon='iconoir-more-vert icon--tiny'
          disabled={isDisabled}
          aria-controls='popup_menu'
          aria-haspopup
          onClick={event => menu.current?.toggle(event)}
          size='small'
          text
        />
        <Menu
          model={chartActions}
          popup
          ref={menu}
          id='popup_menu'
          className='chart-dropdown-actions-menu'
        />
      </div>
    );
  }
);

export { ChartActionsMenu };
export default ChartActionsMenu;
