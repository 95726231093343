import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { type Location, useLocation, useParams } from 'react-router-dom';
import { useSignalR } from 'App';
import { clsx } from 'clsx';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';
import { KeyedMutator } from 'swr';

import NoData from 'components/NoData';
import { UISettings } from 'components/OBXUser/Model/Enums';
import { ProfileResult } from 'components/OBXUser/Model/ProfileResult';
import { SecurityRights } from 'components/OBXUser/Model/SecurityRightsEnum';
import {
  useLoadUserSettings,
  useLoggedInUser,
  useSaveUserSetting,
} from 'components/OBXUser/Services/ProfileHooks';
import TertiaryNavigation from 'components/SecondaryNavigation';
import ToastMessage, {
  ToastMessageRef,
  ToastSeverity,
} from 'components/ToastMessage';
import AllWorksheets from 'components/Worksheets/AllWorksheets';
import {
  WorksheetInternalEventTypes, WorksheetSignalMessageEventTypes,
  WorksheetStores,
} from 'components/Worksheets/Models/Enums';
import { WorksheetResponse } from 'components/Worksheets/Models/WorksheetResponse';
import {
  useCreateNewWorksheet,
  useShareWorksheet,
} from 'components/Worksheets/Services/WorksheetHooks';
import { WorksheetsAPI } from 'components/Worksheets/Services/WorksheetsAPI';
import { ShareWorksheetDialogFooter } from 'components/Worksheets/Templates';
import NoLongerAvailable from 'components/Worksheets/Components/NoLongerAvailable';
import AccessWrapper from 'components/AccessWrapper/AccessWrapper';

import Results from './Components/Results';
import { RightPanel } from './Components/RightPanel';
import {
  DetailsPanelState,
  type SelectedContentMessage,
} from './Components/RightPanel/Models';
import { isMessagesEqual } from './Components/RightPanel/Models/Helpers';
import SearchBar from './Components/SearchBar';
import { emptyHydrator } from './Components/SearchBar/Models/Parsers';
import SharePopup, { SharePopupReferenceProps } from './Components/SharePopup';
import { ShareRequestParams } from './Components/SharePopup/Models';
import Subnavigation from './Components/Subnavigation';
import SubscribePanel from './Components/SubscribePanel';
import { SurveillanceModeEnum } from './Models/Enums';
import { DEFAULT_SEARCH_ITEMS, SearchRequest } from './Models/ReportsRequest';
import { ResultsResponse } from './Models/ReportsResponse';
import { SurveillanceSocket } from './Services/SignalRSocket';
import { SurveillanceApiService } from './Services/SurveillanceService';

import { isWorksheetSharedWithUser } from 'helpers/Share/FilteringFunctions';
import { notNil } from 'helpers/Utils/misc';
import { removeTrailingSlash, stripUUID } from 'helpers/Utils/string';

import { GlobalDialogDisplayEvents } from 'models/shared/DialogDisplay';
import { ApplicationInternalEventTypes } from 'models/shared/Enums';
import eventBus from 'server/EventBus';

import './Surveillance.scss';

interface SurveillanceProps {
  resultsMode?: SurveillanceModeEnum;
}

const Surveillance = ({ resultsMode }: SurveillanceProps): ReactNode => {
  const store = WorksheetStores.Surveillance;

  const location = useLocation();
  const navigate = useNavigate();
  const { worksheetparam, share } = useParams();
  const { getSetting } = useLoadUserSettings();
  const { trigger } = useSaveUserSetting();
  const { newWorksheet } = useCreateNewWorksheet(WorksheetStores.Surveillance);
  const { signal } = useSignalR();
  const { obxuser } = useLoggedInUser();
  const { shareWorksheet } = useShareWorksheet<ShareRequestParams>(store);

  const [isShare, setIsShare] = useState(Boolean(share));
  const [leftColumn, setLeftColumn] = useState<boolean>(true);
  const [isLoadingWorksheet, setIsLoadingWorksheet] = useState<boolean>(false);
  const [searchItems, setSearchItems] = useState<SearchRequest | undefined>(
    DEFAULT_SEARCH_ITEMS
  );
  const [resultSelected, setResultSelected] = useState<ResultsResponse | null>(
    null
  );
  const [lastModified, setLastModified] = useState<DateTime>();
  const [detailsPanelState, setDetailsPanelState] = useState<DetailsPanelState>(
    getSetting(UISettings.SURVEILLANCE_CONFIG)?.detailsPanelState ||
      DetailsPanelState.collapsed
  );
  const [selectedMessages, setSelectedMessages] = useState<ResultsResponse[]>(
    []
  );
  const [selectedContentMessages, setSelectedContentMessages] = useState<
    SelectedContentMessage[]
  >([]);
  const [isWSForbidden, setIsWSForbidden] = useState(false);
  const [isWSNotFound, setIsWSNotFound] = useState(false);

  const [activeWorksheetId, setActiveWorksheetId] = useState<
    string | null | undefined
  >(
    worksheetparam ||
      getSetting(UISettings.SURVEILLANCE_CONFIG)?.activeWorksheetId
  );
  const [activeWorksheetName, setActiveWorksheetName] = useState<
    string | null | undefined
  >(undefined);

  const searchContainerRef = useRef<HTMLElement>(null);

  const activeCldd = getSetting(UISettings.ACTIVE_CLDD);

  useEffect(() => {
    const escalationsAccess = obxuser?.assignedSecurityRights.includes(
      SecurityRights.SurveillanceEscalations
    );
    if (!escalationsAccess && location.state?.noaccess) {
      eventBus.dispatch(ApplicationInternalEventTypes.APP_SHOW_TOAST_MESSAGE, {
        message: 'Sorry, no entities found that you have access to.',
        severity: ToastSeverity.ERROR,
      });
      // clear location state
      navigate({}, { replace: true });
    }
  }, [obxuser, resultsMode, location, navigate]);

  useEffect(() => {
    setResultSelected(null);
  }, [resultsMode]);

  useEffect(() => {
    const socket = SurveillanceSocket.instance;
    socket.init(signal);
  }, [signal]);

  const toast = useRef<ToastMessageRef>(null);
  const onShareParamsRef = useRef<SharePopupReferenceProps | null>(null);

  const addNewWorksheet = useCallback(async (): Promise<void> => {
    setIsLoadingWorksheet(true);
    setActiveWorksheetId(null);
    const data = await newWorksheet({
      store: WorksheetStores.Surveillance,
      hydrator: emptyHydrator,
      name: 'New Report',
    });

    if (!data || !data.worksheetId) {
      return;
    }

    setActiveWorksheetId(data.worksheetId);
    if (worksheetparam) {
      navigate(location.pathname.replace(worksheetparam, data.worksheetId));
    }
    setIsLoadingWorksheet(false);
    setIsWSNotFound(false);
  }, [location.pathname, worksheetparam, navigate, newWorksheet]);

  const onWorksheetUpdated = (
    updatedWorksheet: Partial<WorksheetResponse>,
    storedWorksheets: WorksheetResponse[],
    updateSheetsLocally: KeyedMutator<any>,
    reloadWorksheetsList: KeyedMutator<WorksheetResponse[]>
  ): void => {
    const isPrivateWS = updatedWorksheet.isPrivate;
    const isUsersWS = obxuser && updatedWorksheet.createdBy === obxuser.userId;
    const isSharedToUser = obxuser
      ? isWorksheetSharedWithUser(
        updatedWorksheet.sharedWith || [],
        activeCldd,
        obxuser
      )
      : false;
    const isSheetExists = Boolean(
      storedWorksheets.find(
        sheet => sheet.worksheetId === updatedWorksheet.worksheetId
      )
    );
    const isDeleted = updatedWorksheet.isDeleted;
    const isCurrent = updatedWorksheet.worksheetId === activeWorksheetId;

    if (isSheetExists) {
      // Private - isPrivate = true and createdBy current user
      // Shared - isPrivate = false and sharedWith is empty array OR current user's cldd OR current user
      if ((isUsersWS || (!isPrivateWS && isSharedToUser)) && !isDeleted) {
        updateSheetsLocally(
          storedWorksheets.map(s =>
            s.worksheetId === updatedWorksheet.worksheetId
              ? { ...s, ...updatedWorksheet }
              : s
          ),
          { revalidate: false }
        );
      } else {
        updateSheetsLocally(
          storedWorksheets.filter(
            s => s.worksheetId !== updatedWorksheet.worksheetId
          ),
          { revalidate: false }
        );
      }
    } else {
      if (isSharedToUser) {
        reloadWorksheetsList();
      }
    }

    if (isDeleted && isCurrent) {
      setIsWSNotFound(true);
    }
  };

  useEffect(() => {
    if (isShare) {
      eventBus.dispatch(
        WorksheetInternalEventTypes.WORKSHEET_SHOW_SHARE_POPUP,
        undefined
      );
    }
  }, [isShare]);

  const onRequestAccess = (location?: Location, user?: ProfileResult): void => {
    const link = `${ stripUUID(
      location?.pathname || '',
      true
    ) }/${ activeWorksheetId }/share?users=${ encodeURIComponent(
      user?.emailAddress || ''
    ) }`;
    WorksheetsAPI.sendAccessRequest({
      arg: {
        store: WorksheetStores.Surveillance,
        id: activeWorksheetId || '',
        requestBody: { link },
      },
    })
      .then(() => {
        eventBus.dispatch(
          ApplicationInternalEventTypes.APP_SHOW_TOAST_MESSAGE,
          {
            message: 'Your request has been sent successfully',
            severity: ToastSeverity.SUCCESS,
          }
        );
      })
      .catch(() => {
        eventBus.dispatch(
          ApplicationInternalEventTypes.APP_SHOW_TOAST_MESSAGE,
          {
            message: 'Sorry, something has gone wrong. Please try again later.',
            severity: ToastSeverity.ERROR,
          }
        );
      });
  };

  useEffect(() => {
    if (notNil(worksheetparam) && worksheetparam !== activeWorksheetId) {
      setActiveWorksheetId(worksheetparam);
    }
    setIsWSForbidden(false);
    !isLoadingWorksheet && setIsWSNotFound(false);
  }, [activeWorksheetId, isLoadingWorksheet, worksheetparam]);

  useEffect(() => {
    if (activeWorksheetId) {
      setResultSelected(null);
      setSearchItems(DEFAULT_SEARCH_ITEMS);
    }
  }, [activeWorksheetId]);

  useEffect(() => {
    trigger({
      setting: UISettings.SURVEILLANCE_CONFIG,
      data: {
        activeWorksheetId,
        detailsPanelState,
      },
    });
  }, [activeWorksheetId, detailsPanelState, trigger]);

  useEffect(() => {
    setSelectedContentMessages(items => [
      ...items.filter(
        i =>
          selectedMessages.find(m => isMessagesEqual(i, m)) ||
          (i.parent &&
            selectedMessages.find(
              m => i.parent && isMessagesEqual(i.parent, m)
            ))
      ),
    ]);
  }, [selectedMessages]);

  const onShareDialog = (worksheet: WorksheetResponse): void => {
    const url = `${ removeTrailingSlash(stripUUID(location.pathname, true)) }/${
      worksheet.worksheetId
    }`;
    const onShare = async (): Promise<void> => {
      if (worksheet) {
        await shareWorksheet({
          store: store,
          id: worksheet.worksheetId,
          requestBody: onShareParamsRef.current?.params,
        }).then(() => {
          if (
            onShareParamsRef.current &&
            onShareParamsRef.current.externalUsers.length > 0
          ) {
            SurveillanceApiService.sendAccessGranted(
              {
                userId: onShareParamsRef.current.externalUsers[0].id || '',
                link: url,
              },
              worksheet.worksheetId
            );
          }
          onCancel();
          eventBus.dispatch(
            ApplicationInternalEventTypes.APP_SHOW_TOAST_MESSAGE,
            {
              title: 'Success',
              message: 'Sharing settings saved successfully',
              severity: ToastSeverity.SUCCESS,
            }
          );
        });
      }
    };
    const onCancel = (): void => {
      setIsShare(false);
      navigate(url);
    };

    eventBus.dispatch(GlobalDialogDisplayEvents.DISPLAY, {
      header: `Share ${ worksheet.name }`,
      body: <SharePopup ref={onShareParamsRef} worksheet={worksheet} />,
      footer: (
        <ShareWorksheetDialogFooter onCancel={onCancel} onShare={onShare} />
      ),
      size: 'large',
    });
  };

  return (
    <>
      <Subnavigation />
      <div className='surveillance-container grow-to-fill'>
        <aside
          className={clsx('surveillance-reports-list position--relative', {
            hidden: !leftColumn,
          })}
        >
          <header>
            <h1>Reports</h1>
            <Button
              icon='iconoir-plus icon--tiny icon--ob-orange icon--primary'
              className='report-add'
              text
              size='small'
              onClick={addNewWorksheet}
            >
              Add
            </Button>
          </header>
          <div className='surveillance-reports-list-all-worksheets grow-to-fill'>
            <AllWorksheets
              activeWorksheetId={activeWorksheetId}
              setActiveWorksheetId={setActiveWorksheetId}
              setActiveWorksheetName={setActiveWorksheetName}
              store={store}
              setting={UISettings.SURVEILLANCE_WORKSHEETS}
              currentRoute={removeTrailingSlash(
                stripUUID(location.pathname.replace('escalations', ''), true)
              )}
              preventDeleteByNonOwners
              preventSharingByNonOwners
              onShareDialog={onShareDialog}
              worksheetUpdatedHandler={onWorksheetUpdated}
            />
          </div>
        </aside>
        <AccessWrapper
          hasAccess={!isWSForbidden}
          buttonLabel='Access request'
          onButtonClick={onRequestAccess}
        >
          <div className='surveillance-container-main grow-to-fill position--relative module__canvas'>
            <header className={clsx('search-bar', isWSNotFound && 'search-bar-only-toggle')} ref={searchContainerRef}>
              <Button
                size='small'
                className='surveillance-toggle-left-panel'
                text
                icon={`iconoir-sidebar-${
                  leftColumn ? 'collapse' : 'expand'
                } icon--tiny`}
                onClick={(): void => setLeftColumn(!leftColumn)}
                tooltip={`${ leftColumn ? 'Close' : 'Open' } list of reports`}
                tooltipOptions={{
                  position: 'right',
                }}
              />
              <SearchBar
                isLoadingWorksheet={isLoadingWorksheet}
                searchItems={searchItems}
                setSearchItems={setSearchItems}
                activeWorksheet={activeWorksheetId}
                searchContainerRef={searchContainerRef}
                setLastModified={setLastModified}
                resultsMode={resultsMode}
                onWorksheetForbidden={(): void => setIsWSForbidden(true)}
                onWorksheetNotFound={(): void => setIsWSNotFound(true)}
              />
            </header>
            <nav className='surveillance-container-main__nav'>
              <TertiaryNavigation
                items={[
                  { path: 'results', label: 'Search Results' },
                  { path: 'reviewed', label: 'Reviewed' },
                ]}
              />
              <SubscribePanel
                isDateSet={!!searchItems?.date}
                activeWorksheetId={activeWorksheetId}
              />
            </nav>
            <main
              className={clsx('grow-to-fill', {
                'drawer--active': Boolean(resultSelected),
              })}
              data-cols={
                Boolean(resultSelected) &&
                (detailsPanelState === DetailsPanelState.collapsed
                  ? '9,3'
                  : '5,7')
              }
              data-drawer-style={Boolean(resultSelected) && 'slide'}
              data-drawer-position={
                Boolean(resultSelected) && 'alongside-right'
              }
            >
              {!notNil(activeWorksheetId) && !isLoadingWorksheet && !isWSNotFound && (
                <NoData
                  header='No Report has been added yet'
                  icon='iconoir-folder-plus'
                  isButton={true}
                  buttonText='Add Report'
                  buttonAction={addNewWorksheet}
                />
              )}
              {isWSNotFound &&
                <NoLongerAvailable
                  title="Report"
                  subtitle="Please choose another from the panel on the left hand side or add a new one"
                  addNewWorksheet={addNewWorksheet}
                />
              }
              {notNil(activeWorksheetId) && !isWSNotFound &&(
                <section className='grow-to-fill overflow--hidden'>
                  <Results
                    isLoadingWorksheet={isLoadingWorksheet}
                    selectedMessages={selectedMessages}
                    setSelectedMessages={setSelectedMessages}
                    resultSelected={resultSelected}
                    setResultSelected={setResultSelected}
                    selectedSiblingMessages={selectedContentMessages.filter(m =>
                      Boolean(m.parent)
                    )}
                    searchItems={searchItems}
                    setSearchItems={setSearchItems}
                    lastModified={lastModified}
                    activeWorksheetId={activeWorksheetId}
                    activeWorksheetName={activeWorksheetName}
                    toastRef={toast}
                    resultsMode={resultsMode}
                  />
                </section>
              )}
              {resultSelected && (
                <RightPanel
                  activeWorksheetId={activeWorksheetId}
                  record={resultSelected}
                  setRecordSelected={setResultSelected}
                  selectedMessages={selectedMessages}
                  setSelectedMessages={setSelectedMessages}
                  selectedContentMessages={selectedContentMessages}
                  setSelectedContentMessages={setSelectedContentMessages}
                  panelState={detailsPanelState}
                  setPanelState={setDetailsPanelState}
                  searchRequestFields={searchItems?.searchRequestFields}
                  resultsMode={resultsMode}
                />
              )}
            </main>
          </div>
        </AccessWrapper>
        <ToastMessage ref={toast} />
      </div>
    </>
  );
};

export default Surveillance;
