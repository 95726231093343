import { Button } from 'primereact/button';

import eventBus from 'server/EventBus';
import { ToastSeverity } from 'components/ToastMessage';
import { ApplicationInternalEventTypes } from 'models/shared/Enums';
import { useAuditSanctionsTrack } from 'modules/Sanctions/Services/SanctionsAPI';
import { ResultsModeEnum, SanctionTypeAPI, TrackingType } from 'modules/Sanctions/Models/Enums';

import type { ReactElement, ReactNode } from 'react';
import type {
  IndividualSanctionResponse,
  LegalEntitySanctionResponse,
  VesselSanctionResponse
} from 'modules/Sanctions/Models/SanctionedItems';

import './MarkAsCheckedButton.scss';

interface MarkAsCheckedButtonProps<T> {
  data: VesselSanctionResponse | LegalEntitySanctionResponse | IndividualSanctionResponse;
  resultsMode: ResultsModeEnum;
  label?: ReactNode;
}

export const MarkAsCheckedButton = <T,>(props: MarkAsCheckedButtonProps<T>): ReactElement => {
  const { data, resultsMode, label = 'Check' } = props;

  const { trigger } = useAuditSanctionsTrack(data.id);

  const markAsChecked = async (): Promise<void> => {
    const providerNameProp = resultsMode === ResultsModeEnum.IndividualSanctions ? 'listName' : 'providerName';

    trackingType: TrackingType.MarkAsChecked;
    try {
      await trigger({
        id: data.id,
        sanctionType: SanctionTypeAPI[ResultsModeEnum[resultsMode] as keyof typeof SanctionTypeAPI],
        providerName: data[providerNameProp as keyof typeof data] as string,
        trackingType: TrackingType.MarkAsChecked
      });

      eventBus.dispatch(
        ApplicationInternalEventTypes.APP_SHOW_TOAST_MESSAGE,
        {
          message: 'Selected item is marked as Checked',
          severity: ToastSeverity.SUCCESS,
        }
      );
    } catch (e) {
      eventBus.dispatch(
        ApplicationInternalEventTypes.APP_SHOW_TOAST_MESSAGE,
        {
          message: 'Sorry, something has gone wrong. Please try again later.',
          severity: ToastSeverity.ERROR,
        }
      );
    }
  };

  return <Button
    size='small'
    text
    onClick={markAsChecked}
    className='mark-as-checked--button'
  >
    {label}
  </Button>;
};

export default MarkAsCheckedButton;