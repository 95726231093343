import { additionalSearchPropParser, fieldsParser, hydrator } from 'components/Worksheets/Models/Parsers';
import { BlotterSearchField } from './SearchRequest';
import { BlotterEntitySearchFieldsEnum } from './Enums';

export const tradeSearchWorksheetParsers = {
  propsParser: additionalSearchPropParser,
  fieldsParser,
  hydrator,
};

export const searchRequestItemFactory = <T, >(fields: BlotterSearchField[], type: BlotterEntitySearchFieldsEnum): T[] => {
	return fields.filter(({searchField}) => searchField === type) as T[];
}