import {
  memo,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { clsx } from 'clsx';
import * as Highcharts from 'highcharts/highstock';
import exportingModule from 'highcharts/modules/exporting';
import HighchartsReact, {
  HighchartsReactRefObject,
} from 'highcharts-react-official';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';
import { Dropdown, type DropdownChangeEvent } from 'primereact/dropdown';

import BorealisBar from 'components/BorealisBar';
import ChartActionsMenu from 'components/ChartActionsMenu';
import { ToastMessageRef } from 'components/ToastMessage';

import {
  ActiveChartPrice,
  HistoricalRequest,
} from 'modules/ArtisCharting/Models/ArtisPrices';
import { TenorPeriodsToTimeBlock } from 'modules/ArtisCharting/Models/Enums';
import type {
  TenorPeriods,
  TenorWindowKey,
} from 'modules/ArtisCharting/Models/Packages';
import { useHistoricalPrices } from 'modules/ArtisCharting/Services/hooks';

import './CandleStickChart.scss';

type ComponentParams = {
  price: ActiveChartPrice;
  frequency?: keyof TenorWindowKey;
  setFrequency?: (arg: keyof TenorWindowKey | undefined) => void;
  period?: TenorPeriods;
  setPeriod?: (arg: TenorPeriods | undefined) => void;
  isLoading?: boolean;
  toastRef?: RefObject<ToastMessageRef>;
};

exportingModule(Highcharts);

const CandleStickChart = (params: ComponentParams): JSX.Element => {
  const {
    frequency,
    price,
    period,
    setFrequency,
    setPeriod,
    isLoading: propsIsLoading,
    toastRef,
  } = params;
  const [selectedFrequency, setSelectedFrequency] = useState<keyof TenorWindowKey | undefined>(frequency ?? price.windows[0]?.frequency);
  const [selectedPeriod, setSelectedPeriod] = useState<TenorPeriods | undefined>(period ?? price.windows[0]?.periods[0]);
  const [availablePeriods, setAvailablePeriods] = useState<TenorPeriods[]>([]);
  const [selectedPackage, setSelectedPackage] = useState(price.artispackage);
  const [isDataFullfilled, setIsDataFullfilled] = useState(false);

  const hc = useRef<HighchartsReactRefObject>(null);

  useEffect(() => {
    // remember artis package to prevent sending invalid requests
    if (selectedPackage?.source !== price.artispackage?.source) {
      setSelectedPackage(price.artispackage);
      setSelectedFrequency(frequency ?? price.windows[0]?.frequency);
      setSelectedPeriod(period ?? price.windows[0]?.periods[0]);
    }
  }, [frequency, period, price, selectedPackage?.source]);

  useEffect(() => {
    // required to set available periods on first render
    setAvailablePeriods(price.windows.find(w => w.frequency === selectedFrequency)?.periods ?? []);
  }, [price.windows, selectedFrequency]);

  const initalValue = useCallback((): HistoricalRequest | null => {

    const { product, artispackage, name, index, tenorCode } = price;

    if (artispackage?.source !== selectedPackage?.source) {
      console.warn('Active price package is different than currently selected package');
      return null;
    }

    return {
      artispackage,
      frequency: selectedFrequency,
      index,
      name,
      period: selectedPeriod,
      product,
      tenorCode,
    };
  }, [price, selectedFrequency, selectedPackage, selectedPeriod]);

  const [query, setQuery] = useState<HistoricalRequest | null>(initalValue());
  const { history, error, isLoading } = useHistoricalPrices(query);

  const getExportFileName = useCallback(
    () =>
      query
        ? `${query.name} - ${query.tenorCode} - ${DateTime.now().toFormat(
            'yyyy-dd-MM HH:mm'
          )}`
        : '',
    [query]
  );

  useEffect(() => {
    setQuery(initalValue());
  }, [initalValue, params])

  useEffect(() => {
    if (typeof setFrequency === 'function') {
      setFrequency(selectedFrequency);
    }

    if (typeof setPeriod === 'function') {
      setPeriod(selectedPeriod);
    }
    // eslint-disable-next-line
  }, [selectedFrequency, selectedPeriod]);

  useEffect(() => {
    setIsDataFullfilled(Boolean(history?.results?.length && !error));
  }, [history?.results, error]);

  const handleSelectFrequency = (e: DropdownChangeEvent): void => {
    setSelectedFrequency(e.value);

    const periods = price.windows.find(w => w.frequency === e.value)?.periods ?? [];
    setAvailablePeriods(periods);
    // update selected period once frequency has been changed,
    // ex. in case when frequency has no such period as selected -> select 1st available for that frequency
    setSelectedPeriod(periods[0]);
	}

  const options: Highcharts.Options = {
    series: [{
      name: price.name,
      type: history?.blockType ?? 'candlestick',
      data: history?.results,
    }],
    chart: {
      backgroundColor: 'transparent',
      className: 'artis-chart__container',
      styledMode: true,
    },
    plotOptions: {
      series: { // general options for all chart types
        showInNavigator: true,
        dataGrouping: {
          enabled: false, // disable - don't aggregate data in larger chunks
        },
      },
      line: { // config for 'line' chart
        connectNulls: true, // connect points across null points
        // useOhlcData: true,
      },
    },
    xAxis: {
      labels: { allowOverlap: true, overflow: 'allow', step: 1 },
      showFirstLabel: true,
      showLastLabel: true,
      // ordinal: false, // leave blank spaces if missing data periods
    },
    yAxis: {
      labels: { align: 'center', reserveSpace: false, x: 0 },
      opposite: false, // labels on right (true) or left (false)
      showFirstLabel: true,
      showLastLabel: true,
    },
    rangeSelector: { // disable range and zoom selectors
      enabled: false,
    },
    scrollbar: {
      height: 0, // height 0 hides scrollbar, but allows to display range smoothly
    },
    credits: {
      enabled: false,
    },
    navigator: {
      xAxis: {
        showFirstLabel: true,
        showLastLabel: true,
        labels: {
          enabled: true,
          useHTML: true,
        },
      },
    },
    tooltip: {
      enabled: true,
      borderWidth: 1,
      borderColor: 'var(--panel-border-color)',
    },
    exporting: {
      enabled: false,
      fallbackToExportServer: false,
    },
  };

  function renderChart(): ReactNode {
    if (error) {
      return <div>Something went wrong</div>;
    }

    if (isLoading || propsIsLoading) {
      return <div className='candlestick-chart__loader-container'><BorealisBar /></div>;
    }

    if (history?.results.length) {
      return <HighchartsReact
        ref={hc}
        options={options}
        highcharts={Highcharts}
        constructorType='stockChart'
      />;
    }

    return <div>No data to display</div>;
  }

  return <>
    <div className='direction--column candlestick-chart__settings'>
      <div className='form-input__container'>
        <label htmlFor='candlestick-chart__dropdown-period'>Period</label>
        <div className='candlestick-chart__dropdown-period--container direction--row'>
          <Dropdown
            id='candlestick-chart__dropdown-period'
            className='candlestick-chart__dropdown'
            panelClassName='candlestick-chart__dropdown--panel'
            options={price.windows}
            optionLabel='label'
            optionValue='frequency'
            value={selectedFrequency}
            onChange={(e) => handleSelectFrequency(e)}
          />
          <ChartActionsMenu
            fileName={getExportFileName}
            highchartRef={hc}
            isDisabled={!isDataFullfilled}
            toastRef={toastRef}
          />
        </div>
      </div>
      <div className='form-input__container direction--row align-items--center'>
        <div
          className='candlestick-chart__tabbed-nav'>
          {availablePeriods.map(period =>
            <Button
              key={period}
              className={clsx(
                'p-button--tab-style',
                { 'active': selectedPeriod === period }
              )}
              onClick={() => setSelectedPeriod(period)}
              size='small'
            >
              {period}
            </Button>
          )}
        </div>
      </div>
    </div>
    {renderChart()}
    <footer className='no-background candlestick-chart__footer'>
      {history &&
        <div>Prices in {TenorPeriodsToTimeBlock[selectedPeriod!]} last updated {history.asOf.toFormat("t LLL dd yyyy")} (UTC)</div>}
    </footer>
	</>
};

export default memo(CandleStickChart);
