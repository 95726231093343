import { TradesDataResponse } from '../Models/BlotterResponse';

const mapTradeFields = (trade: TradesDataResponse): Record<string, string> => ({
  'Clearing Id': trade.clearingId,
  Instrument: trade.instrument,
  Buyer: trade.buyerCompany,
  'Buyer Trader': trade.buyerContactName,
  'Buyer Broker': trade.buyerObBroker.userName,
  Seller: trade.sellerCompany,
  'Seller Trader': trade.sellerContactName,
  'Seller Broker': trade.sellerObBroker.userName,
  Quantity: `${trade.quantity.amount}` || '',
  Price: `${trade.price.amount}` || '',
  Date: trade.dateTime.toFormat('dd LLL yyyy, hh:mm'),
});

const tradeToString = (trade: TradesDataResponse, isHTML = false): string => {
  const newLine = isHTML ? '<br>' : '\r\n';
  const fieldNameTag = {
    start: isHTML ? '<b>' : '',
    end: isHTML ? '</b>' : '',
  };
  return `
  ${ Object.entries(mapTradeFields(trade))
    .map(
      ([field, value]) =>
        `${ fieldNameTag.start }${ field }:${ fieldNameTag.end } ${ value }`
    )
    .join(newLine) }
  `.trim();
};

export default tradeToString;
export { tradeToString };
