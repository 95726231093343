import axios, { type AxiosResponse } from 'axios';

import { parsePropsToDateTime } from 'helpers/Utils/misc';
import { getAllFromBitwise } from 'helpers/Utils/enum';

import { parseVesselProps, parseEntityProps, parseIndividualProps } from 'modules/Sanctions/Models/Parsers';

import { AuditScoreType, SanctionSnapshotType } from '../Models/Enums';

import type {
  apiAuditScore,
  apiFixtureAuditFixture,
  apiFixtureAuditSnapshot,
  apiFixtureAuditSummaries,
  apiSanctionSearchHistoryResult,
  fixtureAuditItem,
} from '../Models/apiResponse';
import type { apiGetSanctionSearchHistory } from '../Models/apiRequest';
import type {
  AuditEntityItemDetailed,
  FixtureSearchAudit,
  FixtureVesselAudit,
  ShippingFixtureDetail,
  ShippingFixtureSummaries,
  ShippingFixtureSummary,
} from '../Models/ShippingFixtures';
import type { Snapshot } from '../Models/Snapshots';
import type { SanctionSearchHistoryResult, SanctionSnapshotActivityHistory } from '../Models/SanctionSearchHistory';
import type {
  IndividualSanctionDetailsResponse,
  VesselSanctionResponse,
  LegalEntitySanctionResponse,
  SanctiondItem
} from 'modules/Sanctions/Models/SanctionedItems';

export class ShippingFixturesService {

  static GET_FIXTURES_URL: string = 'fixtureaudit/summary';
  static GET_DETAILED_FIXTURE_URL: string = 'fixtureaudit/fixture';
  static GET_SNAPSHOT_URL: string = 'fixtureaudit/snapshot';
  static GET_SEARCH_HISTORY_URL: string = 'fixtureaudit/search/history';

  static getFixtures = ({skip, take}: {skip: number, take: number}) => {

    return axios.request<ShippingFixtureSummaries, AxiosResponse<apiFixtureAuditSummaries>>({
      url: `${ShippingFixturesService.GET_FIXTURES_URL}/${skip}/${take}`,
      method: 'GET'
    })
    .then(({data}) => {

      const { items: fixtures, totalRecords, count } = data;

      const items = fixtures.map(
        (item) => {

          const { vessel, charterer, owner } = item;

          /** We want the highestst risk from all of the vessel, owener and charterer values */
          const overallRisk = Math.max(...[vessel, owner, charterer].map( ({riskLevel}) => riskLevel));

          const updated = {
            ...item,
            vessel: ShippingFixturesService.parseSummaryItem<FixtureVesselAudit>(vessel, true),
            charterer: ShippingFixturesService.parseSummaryItem<FixtureSearchAudit>(charterer),
            owner: ShippingFixturesService.parseSummaryItem<FixtureSearchAudit>(owner),
            brokers: {
              users: [...item.brokers], list: item.brokers.map(({name}) => name).join(', ')
            },
            overallRisk
          }

          return parsePropsToDateTime<ShippingFixtureSummary>(updated, ['charterPartyDate'])
        }
      );

      return { items, totalRecords, count } as ShippingFixtureSummaries
    })
    .catch(e => { throw e })
  }

  static getFixtureDetails = (fixtureid: string) => axios.request<any, AxiosResponse<apiFixtureAuditFixture>>({
    url: `${ShippingFixturesService.GET_DETAILED_FIXTURE_URL}/${fixtureid}`,
    method: 'GET'
  }).then(({data}) => {

    const { vessel, owner, charterer, brokers, cargos } = data;

    const updated = {
      ...data,
      vessel: ShippingFixturesService.parseDetailedItem(vessel, true),
      owner: ShippingFixturesService.parseDetailedItem(owner),
      charterer: ShippingFixturesService.parseDetailedItem(charterer),
      brokers: {
        users: [...brokers], list: brokers.map(({name}) => name).join(', ')
      },
      cargos: cargos.sort((a, b) => a.parcel - b.parcel).map( cargo => ({
        ...cargo,
        details: `${cargo.quantity ? `${new Intl.NumberFormat('en-US', { notation: 'compact', maximumSignificantDigits: 3 }).format(cargo.quantity)} ` : ''}${cargo.description}`
      }))
    }

    return parsePropsToDateTime<ShippingFixtureDetail>(updated, ['charterPartyDate'])
  })
  .catch(e => { throw e })

  static getSnaphot = async (fixtureid: string | null, snapshotid: string): Promise<Snapshot> => {
    const url = fixtureid === null ?
      `${ShippingFixturesService.GET_SNAPSHOT_URL}/${snapshotid}` :
      `${ShippingFixturesService.GET_SNAPSHOT_URL}/${fixtureid}/${snapshotid}`;

    return axios.request<Snapshot, AxiosResponse<apiFixtureAuditSnapshot>>({
      url,
      method: 'GET',
    })
    .then(async ({data}) => {

      let { sanctionType, snapshot } = data;

      let snap: SanctiondItem;

      switch (sanctionType) {
        case SanctionSnapshotType.Entity:
        default:
          snap = parseEntityProps(
            parsePropsToDateTime<LegalEntitySanctionResponse>(snapshot, ['designatedDate', 'createdOn'])
          );
          break;
        case SanctionSnapshotType.Vessel:
          snap = parseVesselProps(
            parsePropsToDateTime<VesselSanctionResponse>(snapshot, ['createdOn'])
          );
          break;
        case SanctionSnapshotType.Individual:
          snap = parseIndividualProps(
            parsePropsToDateTime<IndividualSanctionDetailsResponse>(snapshot, ['dateDesignated', 'createdOn'])
          );
          break;
      }

      return {
        sanctionType,
        snapshot: snap
      } as Snapshot;
    })
    .catch((e) => {
      console.log("e", e)
      throw e;
    })
  }

  static getSearchHistory = async (url: string, params: { arg: apiGetSanctionSearchHistory; }): Promise<SanctionSearchHistoryResult> =>
    axios.request<SanctionSearchHistoryResult, AxiosResponse<apiSanctionSearchHistoryResult>>({
      url: ShippingFixturesService.GET_SEARCH_HISTORY_URL,
      method: 'POST',
      data: params.arg
    })
      .then(r => ({
        ...r.data,
        snapshotActivityHistory: r.data.snapshotActivityHistory.map(d =>
          parsePropsToDateTime<SanctionSnapshotActivityHistory>(d, ['detailsOpenedDateUtc']))
      }))
      .catch((e) => {
        console.log('Cannot fetch Search history', e);

        throw e;
      });


  /**
   * Takes a basic primitive `apiAuditScore` instance creating additional properties
   * needed to match a return `FixtureSearchAudit` or `FixtureVesselAudit`
   * summary objects
   *
   * @private
   * @param {apiAuditScore} item
   * @returns {T} Either a FixtureVesselAudit or FixtureSearchAudit instance
   */
  private static parseSummaryItem = <T = FixtureSearchAudit>(item: apiAuditScore | fixtureAuditItem, isVessel: boolean = false): T => {

    const parsed = {
      ...item,
      majorStatus: getAllFromBitwise(AuditScoreType, item.score)[0],
    }

    return (isVessel ? { ...parsed, imoNumber: item.value } : parsed) as T
  }


  /**
   * Takes a basic primitive `fixtureAuditItem` instance so appropriate properties
   * such as a snaphots `when` value are transposed into DateTime instances
   *
   * @template [T=AuditEntityItemDetailed]
   * @param {fixtureAuditItem} item
   * @param {boolean} [isVessel=false]
   * @returns {T}
   */
  private static parseDetailedItem = <T = AuditEntityItemDetailed>(item: fixtureAuditItem, isVessel: boolean = false):T => {

    const parsed = {
      ...ShippingFixturesService.parseSummaryItem<T>({...item }, isVessel),
      snapshots: item.snapshots.map( item => parsePropsToDateTime(item, ['when'])),
    }

    return parsed
  }
}


