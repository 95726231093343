import {
  type MouseEventHandler,
  type ReactNode,
  useEffect,
  useState,
} from 'react';
import clsx from 'clsx';
import { Button } from 'primereact/button';

import './EditableContent.scss';

interface EditableContentProps {
  children: ReactNode; // anything: input, dropdown, etc.
  save: () => Promise<boolean>;
  saveButtonDisabled?: boolean;
  saveButtonLoading?: boolean;
  value: string;
  onModeChange?: (arg: boolean) => void;
  onBlur?: MouseEventHandler<HTMLDivElement>;
  className?: string;
}

const EditableContent = (props: EditableContentProps): ReactNode => {
  const {
    children,
    onModeChange,
    save,
    saveButtonDisabled,
    saveButtonLoading,
    value,
    className,
    onBlur,
  } = props;

  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    if (typeof onModeChange === 'function') {
      onModeChange(editMode);
    }
    // eslint-disable-next-line
  }, [editMode]);

  const handleSave = async (): Promise<void> => {
    const saved = await save();

    if (saved) {
      setEditMode(false);
    }
  };

  const onOverlayClick: MouseEventHandler<HTMLDivElement> = e => {
    if (onBlur) {
      onBlur(e);
    } else {
      setEditMode(false);
    }
  };

  return (
    <>
      {editMode && (
        <div
          className='editable-content__container--overlay'
          onClick={onOverlayClick}
        ></div>
      )}
      <div className='editable-content__container'>
        {editMode ? (
          <>
            <form
              className={clsx('form-input__container', className)}
              onKeyDown={e => {
                if (e.key === 'Escape') {
                  setEditMode(false);
                }

                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSave();
                }
              }}
            >
              {children}
            </form>
            <Button
              text
              className='button--save'
              disabled={saveButtonDisabled}
              loading={saveButtonLoading}
              onClick={handleSave}
              severity='success'
              size='small'
            >
              Update
            </Button>
          </>
        ) : (
          <Button
            className='button--edit no--background no--padding'
            icon='iconoir-edit-pencil icon--tiny icon--right'
            iconPos='right'
            onClick={() => setEditMode(true)}
            size='small'
            text
          >
            {value}
          </Button>
        )}
      </div>
    </>
  );
};

export default EditableContent;
export { EditableContent };
