import * as React from "react";
import { useMediaQuery } from 'react-responsive';

import { clsx } from "clsx";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import DateTimeRange, { type ExternalHandles } from 'components/DateTimeRange';
import { laycanStyleRange } from 'helpers/Utils/formatters';
import ButtonDownloads from 'modules/Sanctions/Components/ButtonDownloads';

import SanctionsSearchContainer from './SanctionsSearchContainer';
import { ResultsModeEnum } from "../../Models/Enums";

import type { SearchParams } from 'modules/Sanctions/Models';

interface ISanctionsProps {
  displayLoader: Boolean;
  currentSearchMode: ResultsModeEnum;
  currentSearchParams: SearchParams;
  mutateSearchParams: (arg: Partial<SearchParams>) => void;
}


function SanctionsSearch(props: ISanctionsProps) {

  const { displayLoader, currentSearchMode, currentSearchParams, mutateSearchParams } = props;
  const { dateRange, searchTerm } = currentSearchParams;

  const [isParsingDate, setIsParsingDate] = React.useState<boolean>(false);

  const dateTimeRef = React.useRef<ExternalHandles>(null);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });

  const handleClear = (): void => {
    mutateSearchParams({ searchTerm: '*', dateRange: null });
    dateTimeRef.current?.reset();
  }

  const placeholderSearchModeString = React.useMemo(() => {
    switch (currentSearchMode) {
      case ResultsModeEnum.IndividualSanctions:
        return 'an Individual';
      case ResultsModeEnum.LegalEntitySanctions:
        return 'an Entity';
      case ResultsModeEnum.VesselSanctions:
      default:
        return 'a Vessel';
    }
  }, [currentSearchMode]);

  return <SanctionsSearchContainer>
    <div className="sanctions__search-container grow-to-fill">
      <div className="p-input-icon-left grow-to-fill form-input__container" >
        <i className={clsx("pi", {
          "pi-spinner pi-spin": displayLoader,
          "pi-search": !displayLoader
        })} />
        <InputText
          placeholder={`Search for ${placeholderSearchModeString}`}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            mutateSearchParams({ searchTerm: ev.target.value });
          }}
          onFocus={(ev: React.FocusEvent<HTMLInputElement>) => {
            ev.target.select();
          }}
          className="grow-to-fill"
          value={searchTerm}
        />
      </div>
      <DateTimeRange
        ref={dateTimeRef}
        className='sanctions__search-datetime-input'
        defaultValue={dateRange ? laycanStyleRange(dateRange.fromDate, dateRange.toDate) : ''}
        onDateParsed={(date) => {
          mutateSearchParams({ dateRange: { fromDate: date.fromString, toDate: date.toString } });
          setIsParsingDate(false);
        }}
        onParsingStart={() => setIsParsingDate(true)}
        onDateParseError={() => setIsParsingDate(false)}
        onEmptyValue={() => mutateSearchParams({ dateRange: null })}
        placeholder='Last Updated'
        required={false}
      />
      <Button
        text
        size='small'
        onClick={handleClear}
        disabled={(!searchTerm && !dateRange) || isParsingDate}
      >
        Clear
      </Button>
    </div>
    {!isTabletOrMobile && <ButtonDownloads />}
  </SanctionsSearchContainer>;
}

export default SanctionsSearch;
