import { clsx } from 'clsx'

import { getByNumber } from 'helpers/Utils/enum';
import { ColumnBodyOptions } from 'primereact/column';

import { SanctionStatusEnum } from '../../Models/Enums';

import "./SanctionStatus.scss";
import general from 'helpers/DataTable/Templates/ColumnTemplates/General.module.scss';

type RenderingOpts = {
	includeLabel: boolean;
}

const defaultOpts: RenderingOpts = {
	includeLabel: false
}


export const SanctionStatus = <T,>(data: T, config: Partial<ColumnBodyOptions>, props?: RenderingOpts) => {

	const opts = { ...defaultOpts, ...props };

	const { field } = config;
	let value: number = Array.isArray(data) ? data[1] : +data[field as keyof T];

	//	Some older vessel/legal entity values might return status values of -999
	//	in which case we need to reset these to -1 thus the eqivilent of
	//	SanctionStatusEnum.Unknown
	if (value === -999) { value = -1 }

	const status: string = getByNumber<typeof SanctionStatusEnum>(SanctionStatusEnum, value);

	const cssclass = clsx(
		`cell-sanction-status cell-sanction-status--${status.toLocaleLowerCase()}`,
			{
					"iconoir-arrow-down": +data[field as keyof T] === SanctionStatusEnum.Low,
					"iconoir-priority-high": +data[field as keyof T] === SanctionStatusEnum.High,
					"iconoir-minus": +data[field as keyof T] === SanctionStatusEnum.Medium,
			},
			"icon--small"
	)

	return <>
	{ opts.includeLabel &&
		<label className={clsx(general.label)}>{ config.column?.props.header?.toString() }</label>
	}
	<div className={cssclass}><span>{status}</span></div>
	</>
}

export default SanctionStatus;