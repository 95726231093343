export enum ArtisPricesSignalRMessages {
	PRODUCT_PRICE_UPDATE = 'exchange',
	PRICE_CHANGE_SUBSCRIPTION_UPDATED = 'subscriptionUpdated',
	MARKET_STATUS = 'market-status'
}

export enum PriceDirection {
	Down = -1,
	Static = 0,
	Up = 1
}

export enum PriceSearchError {
	EmptyResults = 0,
	NoProducts = 1,
}

export enum TenorPeriodsToTimeBlock {
	M1 = '1 min',
	M5 = '5 mins',
	M15 = '15 min',
	H1 = '1 hour',
	H4 = '4 hours',
	D = '1 day',
	W = '1 week',
	MN = '1 month',
}

export enum HistoricalPricesRefreshInterval {
	candlestick = 1000 * 60 * 5, // 5 mins
	line = 1000 * 60, // 1 min
}

export enum PriceChangeSubscriptionDirecton {
	Above = 'Above',
	Below = 'Below'
}

export enum PriceChangeSubscriptionStatus {
	Active = 'Active',
	Muted = 'Muted',
	Expired = 'Expired',
	Triggered = 'Triggered'
}

export enum ArtisMarketStatus {
	Open = 'OPEN',
	Closed = 'CLOSED'
}

export enum CloseTimeOptions {
	SwitchOff = 'Switch off',
	EuropeanClose = 'European close (4:30 pm (UTC))',
	LondonClose = 'London close (5:00 pm (UTC))',
	HoustonClose = 'Houston close (9:00 pm (UTC))'
}

export const CloseTimeOptionsTimesMap: Record<CloseTimeOptions, string> = {
  [CloseTimeOptions.SwitchOff]: '',
  [CloseTimeOptions.EuropeanClose]: '16:30',
  [CloseTimeOptions.LondonClose]: '17:00',
  [CloseTimeOptions.HoustonClose]: '21:00'
};