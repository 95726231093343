import axios, { AxiosError, AxiosResponse } from 'axios';
import useSWR, { Key } from 'swr';
import useSWRMutation from 'swr/mutation';

import ErrorToastService from 'components/Errors/ErrorToast/Services';
import { parsePropsToDateTime } from 'helpers/Utils/misc';

import { ResultsModeEnum } from '../Models/Enums';

import type {
	apiVesselSanctionResponse,
	apiLegalEntityResponse,
	apiSearchResponse,
	apiTrackSanction,
	apiIndividualSanctionSearchModel,
	SearchParams
} from '../Models';
import type {
	IndividualSanctionDetailsResponse,
	IndividualSanctionResponse,
	LegalEntitySanctionResponse,
	VesselSanctionResponse
} from '../Models/SanctionedItems';
import type { ResponseError } from 'models/shared/error';

interface ISearchParams extends SearchParams {
    pageSize: number;
    pageNumber: number;
    callbackId?: string;
}

const getFetcherByProp = (mode: ResultsModeEnum) => {
	switch (mode) {
		case ResultsModeEnum.VesselSanctions:
			return SanctionsApi.searchByVessel;
		case ResultsModeEnum.LegalEntitySanctions:
			return SanctionsApi.searchByEntity;
		case ResultsModeEnum.IndividualSanctions:
			return SanctionsApi.searchByIndividual;
	}
}

export const useSanctionsApi = <T = any>(searchParams: SearchParams, mode: ResultsModeEnum) => {
	const { dateRange, searchTerm } = searchParams;
	const shouldFetch: boolean = searchTerm.length >= 1;

	const func = getFetcherByProp(mode) as ((arg: ISearchParams) => Promise<apiSearchResponse<T>>);

	const { data, error, isLoading } = useSWR<apiSearchResponse<T>>(
		shouldFetch
			? `${mode}.${searchTerm}.${dateRange?.fromDate}.${dateRange?.toDate}`
			: null,
		() => func({ searchTerm, dateRange, pageSize: 1000, pageNumber: 1 })
	);

  return { data, error, isLoading };
}

export const useIndividualDetailsApi = (id?: string, listName?: string, shouldFetch?: boolean): { data?: IndividualSanctionDetailsResponse; error: ResponseError; isLoading: boolean; } => {
	const fetch = !!(id && listName && shouldFetch);

    const { data, error, isLoading } = useSWR(
		fetch ? `individualDetails/${listName}/${id}` : null,
        () => SanctionsApi.getIndividualDetails(id!, listName!),
        { revalidateIfStale: false, revalidateOnFocus: false }
    );

    return { data, error, isLoading };
}

export const useAuditSanctionsTrack = (id: string): {
    data: string | void;
    trigger: (extraArgument: apiTrackSanction) => Promise<string | void>;
    isMutating: boolean;
} => {
    const { trigger, data, isMutating } = useSWRMutation<string | void, AxiosError, Key, apiTrackSanction>(
    `audit/${ id }`,
    SanctionsApi.auditSanctionsTrack
  );

  return { trigger, data, isMutating };
};

export class SanctionsApi {

	static searchByVessel = (props: ISearchParams): Promise<apiSearchResponse<VesselSanctionResponse>> =>
		axios.post<any, { data: apiSearchResponse<apiVesselSanctionResponse>; }>('sanctions/vessel/search', props)
			.then(r => ({
				...r.data,
				results: r.data.results.map((item: apiVesselSanctionResponse, serverindex: number) =>
					parsePropsToDateTime<VesselSanctionResponse>({ ...item, serverindex }, ['createdOn', 'designatedDate']))
			}))
			.catch((e) => {
				ErrorToastService.handleError(e, [500, 503]);

				throw e;
			});

	static searchByEntity = (props: ISearchParams): Promise<apiSearchResponse<LegalEntitySanctionResponse>> =>
		axios.post<any, { data: apiSearchResponse<apiLegalEntityResponse>; }>(
			'sanctions/legalentity/search',
			props
		)
			.then(r => ({
				...r.data,
				results: r.data.results.map((result: apiLegalEntityResponse) =>
					parsePropsToDateTime<LegalEntitySanctionResponse>(result, ['createdOn', 'designatedDate']))
			}))
			.catch((e) => {
				ErrorToastService.handleError(e, [500, 503]);

				throw e;
			});

	static searchByIndividual = (props: ISearchParams): Promise<apiSearchResponse<IndividualSanctionResponse>> =>
		axios.post<any, { data: apiSearchResponse<apiIndividualSanctionSearchModel>; }>('sanctions/individual/search', props)
		.then(r => ({
			...r.data,
			results: r.data.results.map((result: apiIndividualSanctionSearchModel) =>
				parsePropsToDateTime<IndividualSanctionResponse>(result, ['createdOn', 'dateDesignated']))
		}))
        .catch((e) => {
            ErrorToastService.handleError(e, [500, 503]);

            throw e;
        });

	static getIndividualDetails = (id: string, listName: string) => axios.get(`sanctions/individual/${listName}/${id}`)
		.then(r => parsePropsToDateTime<IndividualSanctionDetailsResponse>(r.data, ['createdOn', 'dateDesignated']))
		.catch((e) => {
			ErrorToastService.handleError(e, [500, 503]);

			throw e;
		});

    static auditSanctionsTrack = (url: string, params: { arg: apiTrackSanction; }): Promise<string | void> => {
        const data = params.arg;

        return axios.request<string, AxiosResponse<string>>({
            url: 'audit/sanctions/track',
            data,
            method: 'POST'
        })
            .then(results => results.data)
            .catch(e => { throw e; });
    };

}
