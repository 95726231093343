export enum SanctionSnapshotType {
	Vessel = 1,
	Entity = 2,
	Individual = 3
}

export enum AuditScoreType {
	Unknown = 0,
	NoMatchingSearch = 1,
	ShowsRisk = 2,
	NonFixingBrokerSearch = 4,
	OK = 128,
}