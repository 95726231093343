import { humanDate } from 'helpers/Utils/formatters';
import { notNil } from 'helpers/Utils/misc';

import { NameType, SanctionStatusEnum } from './Enums';

import type {
  IndividualSanctionDetailsResponse,
  LegalEntitySanctionResponse,
  SanctiondItem,
  VesselSanctionResponse
} from './SanctionedItems';
import type { apiDOBSearchModel } from './apiResponse';

export type Property = [string, string];
export type PropertyArrayed = [string, Array<any>];
export type Status = [string, SanctionStatusEnum];

/**
 * Given a reponse object containing details of a vessel sanction search, returns
 * two collections of properties. One with text based properties and another
 * with a collection of SanctionStatus'
 *
 * @param {VesselSanctionResponse} payload - The response payload to parse
**/
export const parseVesselProps = (payload: VesselSanctionResponse): SanctiondItem => {

  const { id, providerName, partitionKey } = payload;

  const general: Property[] = Array.from( new Map<string, string | undefined>([
    ['Name', payload.shipName],
    ['IMO Number', payload.imoNumber],
    ['Registered Owner', payload.registeredOwner],
    ['Group Owner', payload.groupBeneficialOwner],
    ['Current Operator', payload.operator],
    ['Status', payload.shipStatus],
    ['Created On', payload.createdOn.isValid ? humanDate(payload.createdOn, { dayName: false }) : 'Unknown'],
  ])).filter<[string, string]>((item): item is Property => {
    const [, value ] = item;
    return value !== undefined
  })

  const status: Status[] =  Array.from(
    new Map<string, SanctionStatusEnum>([
      ['Overall Status', payload.legalOverall ?? -1 ],
      ['EU Sanction List', payload.shipEUSanctionList ?? -1 ],
      ['BES Sanction List', payload.shipBESSanctionList ??-1 ],
      ['OFAC Sanction List', payload.shipOFACSanctionList ??-1 ],
      ['Swiss Sanction List', payload.shipSwissSanctionList ??-1 ],
      ['Owner Australian Sanction List', payload.shipOwnerAustralianSanctionList ??-1 ],
      ['Owner UN Sanction List', payload.shipOwnerUNSanctionList ??-1 ],
      ['Owner BES Sanction List', payload.shipOwnerBESSanctionList ??-1 ],
      ['Owner OFAC Sanction List', payload.shipOwnerOFACSanctionList ??-1 ],
      ['Owner OFAC Sanctioned Country', payload.shipOwnerOFACSanctionedCountry ??-1 ],
      ['Owner FAFT Jurisdiction', payload.shipOwnerFATFJurisdiction ?? -1 ],
      ['Parent Owner FAFT Jurisdiction', payload.shipOwnerParentFATFJurisdiction ?? -1 ],
      ['Parent Owner OFAC Sanctioned Country', payload.shipOwnerParentOFACSanctionedCountry ?? -1 ],
      ['Dark Activity Indicator', payload.shipDarkActivityIndicator ??-1 ],
      ['Owner Parent Company Non Compliance', payload.shipOwnerParentCompanyNonCompliance ??-1 ],
      ['STS Partner Non Compliance Last 12m', payload.shipSTSPartnerNonComplianceLast12m ??-1 ],
      ['Sanctioned Country Port CallLast 3m', payload.shipSanctionedCountryPortCallLast3m ??-1 ],
      ['Sanctioned Country Port CallLast 6m', payload.shipSanctionedCountryPortCallLast6m ??-1 ],
      ['Sanctioned Country Port CallLast 12m', payload.shipSanctionedCountryPortCallLast12m ??-1 ],
      ['Flag Sanctioned Country', payload.shipFlagSanctionedCountry ??-1 ],
      ['Flag Disputed', payload.shipFlagDisputed ??-1 ],
      ['Historical Flag Sanctioned Country', payload.shipHistoricalFlagSanctionedCountry ??-1 ],
    ])
  );


  return { general, status, id, providerName: providerName ?? partitionKey }
}

export const parseEntityProps = (payload: LegalEntitySanctionResponse): SanctiondItem => {

  const { id, providerName, partitionKey } = payload;

  const general: Property[] = Array.from( new Map<string, string | undefined | null>([
    ['Name', payload.fullName],
    ['Short Name', payload.shortCompanyName],
    ['Company Status', payload.companyStatus || 'Unknown'],
    ['Country Name', payload.countryName],
    ['Registration', payload.nationalityofRegistration],
    ['Telephone', payload.telephone],
    ['Source', payload.providerName],
    ['Designated date', payload.designatedDate?.isValid ? humanDate(payload.designatedDate, { dayName: false }) : 'Unknown'],
    ['Created On', payload.createdOn.isValid ? humanDate(payload.createdOn, { dayName: false }) : 'Unknown'],
  ])).filter<[string, string]>((item): item is Property => {
    const [, value ] = item;
    return notNil(value)
  })

  const status: Status[] =  Array.from(
    new Map<string, SanctionStatusEnum>([
			['Company Overall', payload.companyOverallComplianceStatus ?? SanctionStatusEnum.Unknown],
      ['Parent Company Compliance', payload.parentCompanyComplianceRisk ?? SanctionStatusEnum.Unknown ],
      ['FATF Jurisdiction', payload.companyInFATFJurisdiction ?? SanctionStatusEnum.Unknown],
      ['OFAC Sanctioned Country', payload.companyInOFACSanctionedCountry ?? SanctionStatusEnum.Unknown],
      ['Australian Sanction List', payload.companyOnAustralianSanctionList ?? SanctionStatusEnum.Unknown],
      ['BES Sanction List', payload.companyOnBESSanctionList ?? SanctionStatusEnum.Unknown],
      ['EU Sanction List', payload.companyOnEUSanctionList ?? SanctionStatusEnum.Unknown],
      ['Canadian Sanction List', payload.companyOnCanadianSanctionList ?? SanctionStatusEnum.Unknown],
      ['OFAC Non SDN Sanction List', payload.companyOnOFACNonSDNSanctionList ?? SanctionStatusEnum.Unknown],
      ['OFAC SSI List', payload.companyOnOFACSSIList ?? SanctionStatusEnum.Unknown],
      ['OFAC Sanction List', payload.companyOnOFACSanctionList ?? SanctionStatusEnum.Unknown],
      ['Swiss Sanction List', payload.companyOnSwissSanctionList ?? SanctionStatusEnum.Unknown],
      ['UAE Sanction List', payload.companyOnUAESanctionList ?? SanctionStatusEnum.Unknown],
      ['UN Sanction List', payload.companyOnUNSanctionList ?? SanctionStatusEnum.Unknown]
    ])
  );

  return { general, status, id, providerName: providerName ?? partitionKey }
}

export const getDOBString = (dob: apiDOBSearchModel): string => {
  return `${ dob.day ? `${ dob.day }/` : '' }${ dob.month ? `${ dob.month }/` : '' }${ dob.year ? `${ dob.year }` : '' }`;
}

// Date of birth might be just an empty object, so filter it out and do not display
const reduceDOBs = (acc: { value: string; }[], dob: apiDOBSearchModel): { value: string; }[] => {
  const dobString = getDOBString(dob);

  return dobString ? [...acc, { value: dobString }] : acc;
};

export const parseIndividualProps = (payload: IndividualSanctionDetailsResponse): SanctiondItem => {
  const { id, listName } = payload;

  const general: PropertyArrayed[] = Array.from(
    new Map<string, any[]>([
      ['Name', payload.names.filter(n => n.type === NameType.PrimaryName || n.type === NameType.PrimaryNameVariation)],
      ['Title', payload.titles.map(t => ({ value: t }))],
      ['Sanction Imposed', payload.sanctionsImposed ? [{ value: payload.sanctionsImposed }] : []],
      ['AKA', payload.names.filter(n => n.type === NameType.Alias)],
      ['Name non-latin script', payload.nonLatinNames?.map(n => ({ value: n.latinScript })) ?? []],
      ['Date(s) of Birth', payload.individualDetails?.doBs?.reduce(reduceDOBs, []) ?? []],
      ['Nationality', payload.individualDetails?.nationalities ?? []],
      ['National Identification No.', payload.individualDetails?.nationalIdentifierDetails?.map(n => ({ value: n.nationalIdentifierNumber })) ?? []],
      ['National Identification Additional Info.', payload.individualDetails?.nationalIdentifierDetails?.reduce((acc, n) => n.nationalIdentifierAdditionalInformation ? [...acc, { value: n.nationalIdentifierAdditionalInformation }] : acc, [] as { value: string; }[]) ?? []],
      ['Passport details', payload.individualDetails?.passportDetails?.map(p => ({ value: p.number, additionalInformation: p.additionalInformation })) ?? []],
      ['Positions', payload.individualDetails?.positions?.map(p => ({ value: p })) ?? []],
      ['Address', payload.addresses ?? []],
      ['Email Adresses', payload.emailAddresses?.map(e => ({ value: e })) ?? []],
      ['Phone Numbers', payload.phoneNumbers?.map(e => ({ value: e })) ?? []],
      ['Websites', payload.websites?.map(w => ({ value: w })) ?? []],
      ['Programs', payload.programs?.map(p => ({ value: p })) ?? []],
      ['Gender', payload.individualDetails?.gender ? [{ value: payload.individualDetails.gender }] : []],
      ['Date Designated', [{ value: payload.dateDesignated ? humanDate(payload.dateDesignated, { dayName: false }) : 'Unknown' }]],
      ['Designation Source', payload.designationSource ? [{ value: payload.designationSource }] : []],
      ['Created On', [{ value: payload.createdOn ? humanDate(payload.createdOn, { dayName: false }) : 'Unknown' }]],
      ['Last Updated', payload.lastUpdatedList?.map(l => ({ value: humanDate(l, { dayName: false }) })) ?? []],
      ['Place of Birth', payload.individualDetails?.birthDetails?.map(({ townOfBirth, countryOfBirth }) => ({ value: townOfBirth, country: countryOfBirth })) ?? []]
    ])
  );

  return { general, status: [], id, providerName: listName };
}



