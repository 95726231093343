import { ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Accordion, AccordionTab } from 'primereact/accordion';

interface SanctionsSearchContainerProps {
  children: ReactNode;
}

export const SanctionsSearchContainer = (props: SanctionsSearchContainerProps): ReactNode => {
  const { children } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

  if (isMobile) {
    return <Accordion
      className='sanctions-search__accordion'
    >
      <AccordionTab header='Search'>
        {children}
      </AccordionTab>
    </Accordion>;
  }

  return children;
};

export default SanctionsSearchContainer;