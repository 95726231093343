import { type ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';

import EditableField from './Components/EditableField';

import EditableContent from 'modules/Workflows/Components/EditableContent';
import type { apiWorkflowTemplateProperty } from 'modules/Workflows/Models';
import {
  ControlBehaviour,
  TemplateControlTypes,
} from 'modules/Workflows/Models/Enums';
import { useUpdateProperties } from 'modules/Workflows/Services/hooks';

import styles from './EditableInfoField.module.scss';

interface IEditableInfoFieldProps {
  id: string;
  property: apiWorkflowTemplateProperty;
  onUpdated?: (value?: string) => void;
}

const EditableInfoField = ({
  id,
  property,
  onUpdated,
}: IEditableInfoFieldProps): ReactNode => {
  const [editedValue, setEditedValue] = useState<string | undefined>(
    property.control?.type === TemplateControlTypes.NumberInput &&
      !property.value
      ? '0'
      : property.value
  );
  const [validationMessage, setValidationMessage] = useState<string | null>(
    null
  );

  const { updateProperties } = useUpdateProperties();

  useEffect(() => {
    setEditedValue(property.value);
  }, [property]);

  if (!property.control) {
    return <div>{property.value?.toString() || ''}</div>;
  }

  const validate = (value: string = ''): boolean => {
    if (property.validation) {
      const validation = new RegExp(property.validation.rule);

      if (!validation.test(value)) {
        setValidationMessage(property.validation.message);
        return false;
      }
    }

    if (property.control?.mandatory && !value) {
      setValidationMessage('Field is mandatory');
      return false;
    }

    setValidationMessage(null);
    return true;
  };

  const onEditModeChange = (editMode: boolean): void => {
    const newValue = editedValue;
    const isValid = validate(newValue);

    if (!editMode && isValid && property.value !== newValue) {
      const rules = property.control?.rules
        ? Array.isArray(property.control?.rules)
          ? property.control?.rules
          : [property.control?.rules]
        : [];
      const dependentPropertyRef =
        newValue &&
        rules.find(r =>
          r.behaviour === ControlBehaviour.Hide
            ? r.values.includes(newValue)
            : !r.values.includes(newValue)
        )?.ref;
      updateProperties({
        id,
        properties: [
          { ...property, value: newValue || '' },
          ...(dependentPropertyRef
            ? [
              {
                key: dependentPropertyRef,
                value: '',
                control: { type: TemplateControlTypes.TextInput },
              },
            ]
            : []),
        ],
      });
    } else {
      setEditedValue(property.value);
    }
  };

  const onChange = (value: string): void => {
    setEditedValue(value.trim());
    validate(value);
  };

  return (
    <EditableContent
      className={styles.editableFieldContainer}
      value={property.value || ''}
      save={() =>
        Promise.resolve(Boolean(onUpdated && onUpdated(editedValue)) || true)
      }
      onModeChange={onEditModeChange}
      onBlur={
        property.control.type === TemplateControlTypes.DateInput
          ? (e): void => e.preventDefault()
          : undefined
      }
    >
      <EditableField
        key={`editable-field-${ property.key }`}
        className={clsx({
          'p-invalid': validationMessage,
        })}
        value={editedValue}
        control={property.control}
        onChange={onChange}
      />
      {validationMessage?.length && (
        <small className='p-invalid'>{validationMessage}</small>
      )}
    </EditableContent>
  );
};

export default EditableInfoField;
export { EditableInfoField };
