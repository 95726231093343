import useSWR, { useSWRConfig, State, Key } from 'swr';
import useSWRMutation from 'swr/mutation';
import { DateTime, Interval } from 'luxon';

import { asEncoded } from 'helpers/Utils/string';
import { removeItemAt, replaceItemAt } from 'helpers/Utils/collections';

import { ArtisPricesAPI } from './PricesApi';
import { ArtisPackagesAPI } from './PackageAccessApi';
import { HistoricalPricesRefreshInterval, PriceChangeSubscriptionStatus } from '../Models/Enums';
import { HAS_SUBSCRIPTIONS_FLAG_INDEX } from '../Models/Consts';

import type { AxiosError } from 'axios';
import type { ArtisPackage } from '../Models/Packages';
import type { GridColumn } from 'components/OBXUser/Model/ProfileResult';
import type {
	ActiveChartPrice,
	ApiArtisTenorLatest,
	CreatePriceChangeSubscriptionPayload,
	HistoricalPrices,
	HistoricalRequest,
	PriceChangeSubscription,
	PriceChangeSubscriptionFormat,
	PriceProductAsOf,
	Prices,
	SignalRPriceChangeSubscriptionUpdated,
	SignalRArtisProductPriceUpdate,
	TenorPriceTupal,
	PreviousCloseRequest,
} from '../Models/ArtisPrices';

/**
 * Custom hook which will return a list of packages the currently logged in
 * user has access too
 * @returns packages - collection of `ArtisPackage` instacnes
 * @returns error - Where API call fails, returns `AxiosError` instance
 * @returns isLoading - truthy `boolean` value is the response from the API is still in a state of await
 */
export const usePackages = () => {
	const { data, error, isLoading } = useSWR('artis-packages', ArtisPackagesAPI.getPackages, { revalidateOnFocus: false });
	return { packages: data ?? [], error, isLoading }
}

/**
 * Custom hook which when given the unique key for a package name, will
 * return a collection of ALL products contained within the package
 *
 * @param artispackage - unique id `string` for the package a list of products is required for
 * @returns produts - collection of `ArtisProduct` instances
 * @returns error - Where API call fails, returns `AxiosError` instance
 * @returns isLoading - truthy `boolean` value is the response from the API is still in a state of await
 */
export const useProducts = (artispackage: ArtisPackage | null ) => {

	const { data, error, isLoading } = useSWR(
		`artis-products-${asEncoded(artispackage ?? "", false)}`,
		artispackage ? () => ArtisPackagesAPI.getProducts(artispackage.source) : null,
		{ revalidateOnFocus: false});

	return { products: data, error, isLoading }
}



/**
 * Custom hook which will return prices for a passed package. Will only return 
 * prices on products the user has elected to recieve and have saved in 
 * their user profile
 * 
 * @param artispackage - unique id `string` for the package prcies should be returned
 * @param producs - array of product IDs
 * @returns prices - collection of `ProductTenor` instances
 * @returns error - Where API call fails, returns `AxiosError` instance
 * @returns isLoading - truthy `boolean` value is the response from the API is still in a state of await
 * @returns changePricesCache - mutation method
 */
export const useArtisPrices = (artispackage: string | undefined, products: GridColumn[] | undefined) => {

	// console.log('..', artispackage, products)

	if (!Array.isArray(products)) products = [];

	const { cache } = useSWRConfig();
	const ids: string[] = products ? products?.map(p => p.id ?? p.name) : [];
	const cacheKey: string = `artis-prices-${artispackage}-${asEncoded({ids}, false)}`;

	const { data, error, isLoading, mutate } = useSWR(
		cacheKey,
		(artispackage && products?.length > 0) ? () => ArtisPricesAPI.getPrices(artispackage, ids) : null,
		{
			revalidateOnFocus: false,
			shouldRetryOnError: false,
			keepPreviousData: true,
		}
	);

	/**
	 * Mutation method returned in the hook response. Provides a means
	 * through which SignalR message payloads can change the contents
	 * of appropriate product caches
	*/
	const changePricesCache = (update: SignalRArtisProductPriceUpdate) => {

		const { data: current } = cache.get(cacheKey) as State<Prices>;
		const lastUpdated = DateTime.fromISO(update.asOf).toUTC();
		/**
		 * If there is no update, current state OR the update is for a price
		 * older than currently in the cache then we can return without
		 * mutating anything
		*/
		if (!update || !current) return;

		const validChanges = update.tenors.filter(t => t.dx !== 0);
		if (validChanges.length === 0) return;


		const { productId } = update.tenors.at(0) as ApiArtisTenorLatest;
		const { asOf: currentAsOf } = current;

		/** should this update be older than what we currently have we can */
		/** bug out */
		if (Interval.fromDateTimes(lastUpdated, currentAsOf[productId]).length('seconds') < 0) return;

		/**
		 * Merge the updated values from the signalR message with current
		 * product values in the existing cache state.
		 *
		 * To reduce redundant cell rendering in the <Datatable> instance we
		 * step over any 0 direction change values (unless the trading day
		 * has changed)
		 */
		const { parsed: results, maxlength } = ArtisPricesAPI.parseToProductTenors(
			!(lastUpdated.startOf("day") > currentAsOf[productId].startOf("day"))
				? validChanges
				: update.tenors,
			lastUpdated,
			current.results
		);

		const asOf: PriceProductAsOf = {...currentAsOf, [productId]: lastUpdated, master: lastUpdated}

		// console.log("mutating: ", validChanges.length)

		mutate(
			{ asOf, results, maxlength },
			{ revalidate: false }
		)
	}

	const changePricesCacheOnSubscriptionChange = (updatedSub: SignalRPriceChangeSubscriptionUpdated): void => {

		const { data: current } = cache.get(cacheKey) as State<Prices>;

		if (!current) return;

		const index = current.results.findIndex(item => item.tenorCode === updatedSub.tenorCode);
		const item = current.results[index];
		const currentPrice = item?.prices[updatedSub.productId];

		if (!currentPrice || updatedSub.hasSubscriptions === null || currentPrice[HAS_SUBSCRIPTIONS_FLAG_INDEX] === updatedSub.hasSubscriptions) {
			return;
		}

		const newResults = replaceItemAt(
			current.results,
			{
				...item,
				prices: {
					...item.prices,
					[updatedSub.productId]: replaceItemAt(
						currentPrice,
						updatedSub.hasSubscriptions,
						HAS_SUBSCRIPTIONS_FLAG_INDEX // NOTE: index of 'hasSubscriptions' boolean in TenorPriceTupal
					) as TenorPriceTupal
				}
			},
			index
		);

		mutate(
			{ ...current, results: newResults },
			{ revalidate: false }
		);
	};


	return { prices: data, isLoading, error, changePricesCache, changePricesCacheOnSubscriptionChange };
}

export const useHistoricalPrices = (params: HistoricalRequest | null) => {

	let id: string | null = null;

	if (params !== null && params.artispackage?.source && params.period && params.frequency) {
		const { frequency, index, period, product, tenorCode } = params;
		/** create a CRSE checksum hash of the search params for SWR   */
		id = `historical-artis-${asEncoded(`${product}/${tenorCode}/${index}/${period}/${frequency}`, false)}`;
	}

	const { data, error, isLoading } = useSWR(
		id,
		() => ArtisPricesAPI.getHistoricalPrices('', params as HistoricalRequest),
		{
			revalidateOnFocus: false,
			refreshInterval: (data: HistoricalPrices | undefined): number =>
				data ? HistoricalPricesRefreshInterval[data.blockType] : HistoricalPricesRefreshInterval.candlestick
		}
	)

	return { history: data, isLoading, error }
}

export const usePreviousClose = (params: PreviousCloseRequest): {
	data: number | null | undefined,
	error: Error | undefined,
	isLoading: boolean } => {
  let id: string | null = null;
  if (params !== null &&  params.packageId  && params.productId  && params.closeTime) {
    const { packageId, productId, closeTime } = params;
    id = `previous-close-artis-${ asEncoded(`${ packageId }/${ productId }/${ closeTime }`, false) }`;
  }

  const { data, error, isLoading } = useSWR(
    id,
    () => ArtisPricesAPI.getPreviousClose(params),
    {
      revalidateOnFocus: false,
      refreshInterval: HistoricalPricesRefreshInterval.line
    }
  );

  return { data, isLoading, error };
};

export const useGetPriceChangeSubscriptionFormats = (): { data?: PriceChangeSubscriptionFormat[], isLoading: boolean, error?: AxiosError; } => {
	const { data, error, isLoading } = useSWR<PriceChangeSubscriptionFormat[], AxiosError, Key>(
		'price-change-subscriptions-format',
		() => ArtisPricesAPI.getPriceChangeSubscriptionFormats(),
		{
			revalidateOnFocus: false
		}
	);

	return { data, isLoading, error };
};

type GetPriceChangeSubscriptionReturnType = {
	isLoading: boolean;
	data?: PriceChangeSubscription[];
	error?: AxiosError;
	changeSubscriptionsCacheOnSubscriptionUpdate: (arg: SignalRPriceChangeSubscriptionUpdated) => PriceChangeSubscription[];
};

export const useGetPriceChangeSubscriptions = (params: ActiveChartPrice | null): GetPriceChangeSubscriptionReturnType => {
	const { cache } = useSWRConfig();

	const { data, error, isLoading } = useSWR<PriceChangeSubscription[], AxiosError, Key>(
		params ? `price-change-subscriptions-${params.product}-${params.tenorCode}` : null,
		() => ArtisPricesAPI.getPriceChangeSubscriptions('', params as ActiveChartPrice),
		{
			revalidateOnFocus: false,
		}
	);

	const changeSubscriptionsCacheOnSubscriptionUpdate = (updatedSub: SignalRPriceChangeSubscriptionUpdated): PriceChangeSubscription[] => {
		const key = `price-change-subscriptions-${updatedSub.productId}-${updatedSub.tenorCode}`;
		const subscriptionsState = cache.get(key) as State<PriceChangeSubscription[]>;

		if (!subscriptionsState || !subscriptionsState.data) {
			return [];
		}

		let newData: PriceChangeSubscription[];

		if(updatedSub.isDeleted) {
			newData = subscriptionsState.data.filter((s) => s.id !== updatedSub.id);
		} else {
			const index = subscriptionsState.data.findIndex(item => item.id === updatedSub.id);

			switch (updatedSub.status) {
				case PriceChangeSubscriptionStatus.Active:
				case PriceChangeSubscriptionStatus.Muted:
					newData = replaceItemAt(
						subscriptionsState.data,
						updatedSub,
						// replace item or insert new sub at the end of collection
						index > -1 ? index : subscriptionsState.data.length
					);
					break;
				case PriceChangeSubscriptionStatus.Expired:
				case PriceChangeSubscriptionStatus.Triggered:
					newData = index > -1 ?
						removeItemAt(
							subscriptionsState.data,
							index
						) :
						subscriptionsState.data;
					break;
				default:
					newData = subscriptionsState.data;
			}
		}

		cache.set(key, { ...cache.get(key), data: newData });

		return newData;
	};

	return { data, isLoading, error, changeSubscriptionsCacheOnSubscriptionUpdate };
};

export const useCreatePriceChangeSubscription = (): { trigger: (params: CreatePriceChangeSubscriptionPayload) => Promise<PriceChangeSubscription | undefined>; isMutating: boolean; error?: AxiosError; } => {
	const { cache } = useSWRConfig();

	const { error, isMutating, trigger } = useSWRMutation<PriceChangeSubscription, AxiosError | undefined, Key, CreatePriceChangeSubscriptionPayload>(
		`price-change-subscription`,
		ArtisPricesAPI.createPriceChangeSubscription,
		{
			onSuccess(data) {
				const key = `price-change-subscriptions-${data.productId}-${data.tenorCode}`;
				const currData = (cache.get(key)?.data ?? []) as PriceChangeSubscription[];

				cache.set(key, { ...cache.get(key), data: [...currData, data] as State });
			},
		}
	);

	return { trigger, isMutating, error };
};

export const useUpdatePriceChangeSubscription = (): { trigger: (params: PriceChangeSubscription) => Promise<PriceChangeSubscription | undefined>; isMutating: boolean; error?: AxiosError; } => {
	const { cache } = useSWRConfig();

	const { error, isMutating, trigger } = useSWRMutation<PriceChangeSubscription, AxiosError | undefined, Key, PriceChangeSubscription>(
		`price-change-subscription`,
		ArtisPricesAPI.updatePriceChangeSubscription,
		{
			onSuccess(data) {
				const key = `price-change-subscriptions-${data.productId}-${data.tenorCode}`;
				const currData = (cache.get(key)?.data ?? []) as PriceChangeSubscription[];
				const index = currData.findIndex(n => n.id === data.id);

				cache.set(key, { ...cache.get(key), data: replaceItemAt(currData, data, index) as State<PriceChangeSubscription[]> });
			},
		}
	);

	return { trigger, isMutating, error };
};

export const useDeletePriceChangeSubscription = (): {
  trigger: (
    params: PriceChangeSubscription
  ) => Promise<PriceChangeSubscription | undefined>;
  isMutating: boolean;
  error?: AxiosError;
} => {
  const { cache } = useSWRConfig();

  const { error, isMutating, trigger } = useSWRMutation<
    PriceChangeSubscription,
    AxiosError | undefined,
    Key,
    PriceChangeSubscription
  >('delete-price-change-subscription', ArtisPricesAPI.deletePriceChangeSubscription, {
    onSuccess(data) {
      const key = `price-change-subscriptions-${data.productId}-${data.tenorCode}`;
			const cacheItem = cache.get(key)
			const currData: PriceChangeSubscription[] = (cacheItem?.data ?? []);
			
			cache.set(key, { ...cacheItem, data: currData.filter(x => x.id !== data.id) });
    },
  });

  return { trigger, isMutating, error };
};
