import { useState, ReactElement } from 'react';
import { Button } from 'primereact/button';
import clsx from 'clsx';

import { BorealisBarPortal } from 'components/BorealisBar';

import { ResultsModeEnum } from 'modules/Sanctions/Models/Enums';
import { SanctionSnapshotType } from 'modules/ShippingFixtures/Models/Enums';
import SanctionDetails from 'modules/Sanctions/Components/SanctionDetails';
import SanctionedIndividualDetails from 'modules/Sanctions/Components/SanctionedIndividualDetails';
import { useSanctionSearchSanpshot } from 'modules/ShippingFixtures/Services/hooks';

import styles from './Snapshot.module.scss';

type ComponentProps = {
  snapshotid: string;
  fixtureid: string | null;
  target: HTMLElement;
  showToggler?: boolean;
  className?: string;
}

const Snapshot = ({ target, snapshotid, fixtureid, showToggler = true, className }: ComponentProps): ReactElement => {

  const [openState, setOpenState] = useState<boolean>(!showToggler);

  const { data, error, isLoading } = useSanctionSearchSanpshot(snapshotid, fixtureid, openState);

  if (error && !isLoading) {
    return <div><i className='iconoir-xmark icon--small icon--red' />Something went wrong...</div>;
  }

  return <>
    {showToggler && <div className={styles.container}>
      <Button
        text
        size='small'
        icon={`iconoir-nav-arrow-${openState ? 'down' : 'right'} icon--small`}
        onClick={() => setOpenState(!openState)}
      />
      <label>Sanction Report Snapshot</label>
    </div>}
    { data && openState &&
      <div className={clsx(styles.detail, className)}>
        {data.sanctionType !== SanctionSnapshotType.Individual ?
          <SanctionDetails
            selectedItem={data.snapshot}
            resultsMode={ResultsModeEnum.Snapshot}
            definitionListHeader='Snapshot'
            opts={{
              parseProps: false,
              trackView: false
            }}
          /> :
          <SanctionedIndividualDetails
            selectedItem={data.snapshot}
            opts={{
              parseProps: false,
              trackView: false
            }}
          />}
      </div>
    }
    {isLoading &&
      <BorealisBarPortal target={target} />
    }
  </>;
}

export default Snapshot;
