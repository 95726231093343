import { ReactElement, useMemo, useRef } from 'react';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import axios from 'axios';
import fileDownload from 'js-file-download';

import ErrorToastService from 'components/Errors/ErrorToast/Services';

export const ButtonDownloads = (): ReactElement => {
  const menu = useRef<Menu>(null);

  const downloadFile = (path: string, file: string): void => {

    //  path should be root relative so if doesn't start and end with a /
    //  then lets add them
    path = (path.match(/(^\/.*\/$)+/g)) ? path : `/${path}/`;

    const url: string = `${window.location.origin}${path}${file}`;
    axios({
      url,
      method: 'GET',
      responseType: 'blob'
    })
      .then(r => fileDownload(r.data, file))
      .catch((e) => {
        ErrorToastService.handleError(e, [500, 503]);

        // handle error state
        throw e;
      });
  };

  const downloadItems = useMemo(() => [
    {
      label: 'Sanctions Procedure',
      command: () => {
        downloadFile('/', 'Oil Brokerage - Ship Brokering Sanctions Procedure.pdf');
      }
    },
    {
      label: 'Russian Trade Form',
      command: () => {
        downloadFile('/', 'Russian – General Trade Licence Record Form.pdf');
      }
    },
    {
      label: 'UML Price Cap Compliance',
      command: () => {
        downloadFile('/', 'UML - Oil Price Cap Sanctions Compliance 03.03.2023.pdf');
      }
    },
  ], []);

  return <>
    <Menu
      model={downloadItems}
      popup
      ref={menu}
    />
    <Button
      icon=' iconoir-nav-arrow-down icon--small icon--ob-orange icon--right'
      size='small'
      outlined
      onClick={(e) => menu.current?.toggle(e)}
    >
      <i className='iconoir-download icon--small icon--ob-orange' />
      Downloads
    </Button>
  </>;
};

export default ButtonDownloads;