import { useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import clsx from 'clsx';

import { useAuditSanctionsTrack, useIndividualDetailsApi } from 'modules/Sanctions/Services/SanctionsAPI';
import { SanctionTypeAPI, TrackingType } from 'modules/Sanctions/Models/Enums';
import { parseIndividualProps, type PropertyArrayed } from 'modules/Sanctions/Models/Parsers';
import { DETAILS_NO_SELECTED_ITEM_MESSAGE } from 'modules/Sanctions/Models/Consts';

import AliasQuality from '../../Templates/AliasQuality';

import type { IndividualSanctionResponse, SanctiondItem } from 'modules/Sanctions/Models/SanctionedItems';

import './SanctionedIndividualDetails.scss';

type DetailOpts = {
    parseProps: boolean,
    trackView?: boolean,
};

const defaultOpts: DetailOpts = {
    parseProps: true,
    trackView: true,
}

interface SanctionedIndividualDetailsProps {
    selectedItem: IndividualSanctionResponse | SanctiondItem;
    opts?: DetailOpts;
}

export default function SanctionedIndividualDetails (props: SanctionedIndividualDetailsProps): JSX.Element {
    const { selectedItem } = props;
    const opts: DetailOpts = { ...defaultOpts, ...props.opts };

    const [details, setDetails] = useState<SanctiondItem | null>(null);

    const { data } = useIndividualDetailsApi(selectedItem?.id, selectedItem?.listName, opts.parseProps);
    const { trigger: trackSanctionView } = useAuditSanctionsTrack(selectedItem?.id);

    useEffect(() => {
        if (opts.parseProps) {
            if (!data) {
                return;
            }

            setDetails(parseIndividualProps(data));
        } else {
            setDetails(selectedItem as SanctiondItem);
        }
    }, [data, selectedItem]);

    useEffect(() => {
        if (!opts.trackView || !selectedItem) return;

        /** Tracking of a view should only happen if it's ready and we want it too  */
        trackSanctionView({
            id: selectedItem.id,
            providerName: selectedItem.listName as string,
            sanctionType: SanctionTypeAPI.IndividualSanctions,
            trackingType: TrackingType.RowClick
        });
    }, [trackSanctionView, selectedItem]);

    if (!details) {
        return <div>{DETAILS_NO_SELECTED_ITEM_MESSAGE}</div>;
    }

    return <div className='direction--column overflow--y'>
        {(details.general as PropertyArrayed[]).map(([key, value]) =>
            <div key={key} className="individual-sanction-detail--container">
                {value.length ?
                    <DataTable
                        className={clsx('grow-to-fill header--micro', {
                            'column--aka': key === 'AKA',
                            'column--pob': key === 'Place of Birth',
                            'column--address': key === 'Address'
                        })}
                        dataKey='value'
                        value={value}
                    >
                        <Column
                            header={key}
                            field='value'
                        />

                        {key === 'Address' &&
                            <Column
                                header="Postal Code"
                                field='postalCode'
                            />
                        }
                        {key === 'Address' &&
                            <Column
                                header="Country"
                                field='country'
                            />
                        }
                        {key === 'AKA' &&
                            <Column
                                header="Quality"
                                body={AliasQuality}
                            />
                        }
                        {key === 'Place of Birth' &&
                            <Column
                                field='country'
                            />
                        }
                    </DataTable>
                    : <></>
                }
            </div>
        )}
    </div>

}