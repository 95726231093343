import axios, { type AxiosError } from 'axios';
import useSWR, { Key } from 'swr';
import useSWRMutation from 'swr/mutation';

import ErrorToastService from 'components/Errors/ErrorToast/Services';

import { SubscriptionsResponse } from '../../../Models/ReportsResponse';

import { ResponseError } from 'models/shared/error';

export const SURVEILLANCE_SUBSCRIPTION_CACHE: string = 'surveillance-subscriptions';

export const useGetSubscriptions = (worksheetId?: string | null): { data: SubscriptionsResponse[] | undefined | null, error: ResponseError, isLoading: boolean } => {
  const arg: string | null = !worksheetId ? null : `${ SURVEILLANCE_SUBSCRIPTION_CACHE }-${ worksheetId }`;
  const {data, error, isLoading} = useSWR(arg,
    () => SubscribePanelApiService.getSubscriptions(worksheetId),
    { revalidateOnFocus: false });
  return {data, error, isLoading};
};

export const useAddSubscription = (worksheetId?: string | null): { trigger: (params: Partial<SubscriptionsResponse>) => Promise<SubscriptionsResponse | undefined>; isMutating: boolean; error?: AxiosError; } => {
  const { error, isMutating, trigger } = useSWRMutation<SubscriptionsResponse, AxiosError | undefined, Key, Partial<SubscriptionsResponse>>(
    `${ SURVEILLANCE_SUBSCRIPTION_CACHE }-${ worksheetId }`,
    SubscribePanelApiService.addSubscription
  );

  return { trigger, isMutating, error };
};

export const useUpdateSubscription = (worksheetId?: string | null): { trigger: (params: SubscriptionsResponse) => Promise<SubscriptionsResponse | undefined>; isMutating: boolean; error?: AxiosError; } => {
  const { error, isMutating, trigger } = useSWRMutation<SubscriptionsResponse, AxiosError | undefined, Key, SubscriptionsResponse>(
    `${ SURVEILLANCE_SUBSCRIPTION_CACHE }-${ worksheetId }`,
    SubscribePanelApiService.updateSubscription
  );

  return { trigger, isMutating, error };
};

export class SubscribePanelApiService
{
  static getSubscriptions = (worksheetId?: string | null): Promise<SubscriptionsResponse[] | null> =>
    axios<SubscriptionsResponse[]>({
      url: `audit/surveillance/${ worksheetId }/subscriptions`,
      method: 'GET',
    })
      .then(response => response.data)
      .catch(e => {
        ErrorToastService.handleError(e, [500, 503]);
        return null;
      });

  static addSubscription = async (url: string, params: { arg: Partial<SubscriptionsResponse>; }): Promise<SubscriptionsResponse> => {
    return axios.request({
      url: `audit/surveillance/${ params.arg.worksheetId }/subscriptions`,
      method: 'POST',
      data: params.arg
    })
      .then(response => response.data)
      .catch((e) => {
        // re-throw error
        throw e;
      });
  };

  static updateSubscription = async (url: string, params: { arg: SubscriptionsResponse; }): Promise<SubscriptionsResponse> => {
    return axios.request({
      url: `audit/surveillance/${ params.arg.worksheetId }/subscriptions/${ params.arg.id }`,
      method: 'PATCH',
      data: params.arg
    })
      .then(response => response.data)
      .catch((e) => {
        // re-throw error
        throw e;
      });
  };
}
