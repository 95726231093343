import axios from 'axios';
import type { AxiosError, AxiosResponse } from 'axios';
import { DateTime } from 'luxon';

import ErrorToastService from 'components/Errors/ErrorToast/Services';
import { replaceItemAt } from 'helpers/Utils/collections';
import { capFirstLetter, stringToLexicographic } from 'helpers/Utils/string';

import { PriceDirection, PriceSearchError } from '../Models/Enums';

import type {
	ApiArtisPayload,
	ApiArtisTenorLatest,
	ProductTenor,
	HistoricalPrices,
	HistoricalRequest,
	Prices,
	PriceProductAsOf,
	TenorPriceTupal,
	PriceChangeSubscription,
	ActiveChartPrice,
	PriceChangeSubscriptionFormat,
	CreatePriceChangeSubscriptionPayload,
	PreviousCloseRequest,
} from '../Models/ArtisPrices';

import { apiCurvesPayload } from '../Models/apiResponse'


export class ArtisPricesAPI {

	static getPrices = (packageId: string, products: string[]): Promise<Prices> => {
		/**
		 * If we dont pass any products. Little point in round tripping to the
		 * server APIs. Just return an immediate empty results collection
		*/
		if (!products.length) {
			return Promise.resolve({
				asOf: { master: DateTime.now() },
				results: [],
				error: PriceSearchError.NoProducts
			})
		}

		return axios.request<null, AxiosResponse<apiCurvesPayload>>({
			url: 'datafeed',
			method: 'POST',
			data: { products: [...products], packageId }
		})
		.then ( async (result) => {

			const { asOf: asOfString, tenors: results } = result.data;

			const lastUpdated = DateTime.fromISO(asOfString).toUTC();

			if (!results.length) {
				/**
				 * When the API returns no prices - for example the selected products
				 * dont have any values, then we can return an empty collection and
				 * include the PriceSearch.NoResults error code
				 */
				return { asOf: { master: lastUpdated }, results: [], error: PriceSearchError.EmptyResults}
			}

			const { parsed, maxlength } = ArtisPricesAPI.parseToProductTenors(results, lastUpdated);

			const asOf: PriceProductAsOf = products.reduce(
				(a, c) => ({...a, [c]: lastUpdated}),
				{ master: lastUpdated }
			)

			return {
				asOf, results: ArtisPricesAPI.sortByIndexCode(parsed), maxlength
			}
	})}

	static getHistoricalPrices = (url: string, params: HistoricalRequest): Promise<HistoricalPrices> => {
		const { artispackage, frequency, index: tenorIndex, period, product: productId, tenorCode } = params;

		return axios.request<HistoricalRequest, { data: HistoricalPrices & { asOf: string; }; }>({
			url: `/datafeed/products/${artispackage!.source}`,
			method: 'POST',
			data: {
				productId,
				tenorCode,
				tenorIndex,
				frequency: capFirstLetter(frequency!), // BE requires capitalized value
				period
			}
		}).then((response) => {
			return {
				...response.data,
				asOf: DateTime.fromISO(response.data.asOf).toUTC()
			};
		}
		).catch((e) => {
			// re-throw error
			throw e;
		});
	};

  static getPreviousClose = (params: PreviousCloseRequest): Promise<number> => {
    const { packageId, productId, closeTime } = params;

    return axios.request({
      url: `/datafeed/close/${ packageId }`,
      method: 'POST',
      data: {
        productId,
        closeTime
      }
    }).then(response => response.data)
      .catch(e => {
        ErrorToastService.handleError(e, [400, 503]);
        return null;
      });
  };

	static getPriceChangeSubscriptionFormats = async (): Promise<PriceChangeSubscriptionFormat[]> => {
		return axios.get('/exchange/user/subscriptions/formats')
			.then((response) => response.data)
			.catch((e) => {
				// re-throw error
				throw e;
			});
	};

	static getPriceChangeSubscriptions = async (url: string, params: ActiveChartPrice): Promise<PriceChangeSubscription[]> => {
		const { tenorCode, product } = params;

		return axios.get(`/exchange/user/subscriptions?productId=${product}&tenorCode=${tenorCode}`)
			.then((response) => response.data)
			.catch((e) => {
				// re-throw error
				throw e;
			});
	};

	static createPriceChangeSubscription = async (url: string, params: { arg: CreatePriceChangeSubscriptionPayload; }): Promise<PriceChangeSubscription> => {
		return axios.request({
			url: '/exchange/user/subscriptions',
			method: 'POST',
			data: params.arg
		})
			.then(response => response.data)
			.catch((e) => {
				// re-throw error
				throw e;
			});
	};

	static updatePriceChangeSubscription = async (url: string, params: { arg: PriceChangeSubscription; }): Promise<PriceChangeSubscription> => {
		return axios.request({
			url: '/exchange/user/subscriptions',
			method: 'PUT',
			data: params.arg
		})
			.then((response) => response.data)
			.catch((e) => {
				// re-throw error
				throw e;
			});
	};

  static deletePriceChangeSubscription = async (
    url: string,
    params: { arg: PriceChangeSubscription }
  ): Promise<PriceChangeSubscription> =>
    axios
      .request({
        url: `/exchange/user/subscriptions/${params.arg.id}`,
        method: 'DELETE',
      })
      .then(() => params.arg);

	static formatTenorName = (name: string): string => {
		return name.replace("-", " ").toLocaleUpperCase();
	}

	/**
	 * Converts the passed API response items, pivoting the values into a
	 * collection of row tenors, automaitcally ordered by month, quarter,
	 * or cal (year)
	 *
	 * @param items - Collection of prices returned directly from an API call
	 * @returns ProductTenor[]
	 */
	static parseToProductTenors = (items: ApiArtisTenorLatest[], asOf: DateTime, initiator: ProductTenor[] = []): { parsed: ProductTenor[], maxlength: number } => {
		let maxlength: number = 0;
		const parsed = items.reduce(
			(a: ProductTenor[], curr: ApiArtisTenorLatest) => {

				const { productId, tenorCode, tenorName, change, latest, type, dx, index, hasSubscriptions } = curr;

				const cindex: number = a.findIndex(t => t.tenorCode === tenorCode);
				const exists: boolean = cindex !== -1;

				maxlength = tenorName.length > maxlength ? tenorName.length : maxlength;

				const tenor: ProductTenor = exists ? a[cindex] : {
					tenorCode,
					keys: "",
					prices: {},
					type,
					tenorName: {
						display: ArtisPricesAPI.formatTenorName(tenorName),
						original: tenorName
					},
					sortIndex: ArtisPricesAPI.indexToSortValue(index),
					index,
				};

				const prices = {
					...tenor.prices,
					[productId]: [latest, change, dx ?? PriceDirection.Static, asOf, hasSubscriptions] as TenorPriceTupal
				};

				/**
				 * keys property contains map of all product ids available in the
				 * row. Makes for more performant validation of selectable cells
				 * in the datatable.
				 */
				const keys = tenor.keys.includes(productId) ? tenor.keys : `${tenor.keys}${productId}`;

				/** Either replace the existing OR insert as new */
				return exists ? replaceItemAt(a, {...tenor, prices, keys}, cindex) : [...a, {...tenor, prices, keys} ]
			},
			initiator
		)

		return { parsed, maxlength }
	}

	/**
	 * Given a passed index string, will convert into a numeric value so the
	 * sort order of blamo, month, quarter, cal can be honoured
	 *
	 * @param index - string to convert
	 * @returns number
	 */
	private static indexToSortValue = (indexstr: string): number => {
		
		/** 
		 * shipping tenors aren't formatted the same as commodities
		 * so we cant match using the blamo-month-quarter-cal priority
		 * stack. Instead we'll return lexicographic reprentation
		 * of the first 4 alhpabetical characters in the passed index string
		*/
		if (!/\d+|(balmo)/gi.test(indexstr)) return stringToLexicographic(indexstr);

		
		/**
		 * order of priority the first letter of the tenorIndex prop
		 * will be compared against
		 */
		const typeOrder: string = 'BMQC';

		const [ type, ...val ] = indexstr;

		/** When making the index a numberr everything must be min 3 digits */
		const intVals: number = +val.join('');
		const num: number = +`${typeOrder.indexOf(type)}${isNaN(intVals) ? 0 : intVals}`;
		const multiple: number = (val.length === 1) ? 10 : 1;

		return num * multiple;
	}

	private static sortByIndexCode = (unsorted: ProductTenor[]): ProductTenor[] => unsorted.sort((a, b) => a.sortIndex - b.sortIndex);

}