import axios, { AxiosResponse, type AxiosError } from 'axios';
import useSWR, { useSWRConfig, State, Key, mutate } from 'swr';
import useSWRMutation from 'swr/mutation';

import { asEncoded } from 'helpers/Utils/string';
import { removeItemAt, replaceItemAt } from 'helpers/Utils/collections';

import { EntitySearchFieldsEnum } from 'components/EntitySearch/Models/Enums';

import { BlotterDataApi } from './BlotterAPI';
import { DEFAULT_PRICE, QUANTITY_UNITS, TRADES_DATA_KEY } from '../Models/Consts';
import type { apiSearchRequest, apiSearchResponse } from '../Models';
import type { TradeSearchRequest } from '../Models/SearchRequest';
import type {
  BlotterDataResponse,
  ExportTradesPayload,
  TradeDetailsRequest,
  TradeDetailsResponse,
  TradesDataResponse,
  TradeSide,
} from '../Models/BlotterResponse';


export const useGetTrades = (arg?: TradeSearchRequest) => {

  let cacheKey: string | null;

  let query: apiSearchRequest;


  switch (true) {
    case !arg:
      // no args -> don't fetch anything
      cacheKey = null;
      break;
    case !arg?.searchRequestFields?.length && !arg?.dateTime && !arg?.prices?.length && !arg?.quantities?.length && !arg?.clearingIds?.length && !arg?.orderByFields:
      // no search items nor date -> all items cache
      cacheKey = `${TRADES_DATA_KEY}-${arg.pageNumber}`;
      query = { ...arg } as apiSearchRequest;
      break;
    default:
      query = {
        ...arg,
        // special treatment for 'OBBroker'
        // because we are searching by 'userId' and not 'userName' - hence use searchEntityId instead of regular 'searchTerm'
        searchRequestFields: arg.searchRequestFields?.map(item =>
          item.searchField === EntitySearchFieldsEnum.OBBroker ? { ...item, searchTerm: item.searchEntityId! } : item),
        prices: (arg?.prices ?? []).map(({ from, to }) => ({ from, to })), // send only necessary fields
        quantities: (arg?.quantities ?? []).map(({ from, to }) => ({ from, to })),
        clearingIds: (arg.clearingIds ?? []).map((v) => typeof v === 'string' ? v : v.searchTerm)
      }
      // otherwise -> use arg as an unique key
      cacheKey = `${TRADES_DATA_KEY}-${asEncoded(query, false)}`;
      break;
  }

  const { data, error, isLoading, isValidating } = useSWR<BlotterDataResponse, AxiosError>(
    cacheKey,
    () => BlotterDataApi.getTradesData(query!),
    {
      revalidateOnFocus: false
    }
  );

  return {
    data,
    error,
    isLoading,
    isValidating,
  };
};

export const useGetTradeDetails = (tradeId: string | null): { data: TradeDetailsResponse | undefined, error: AxiosError, isLoading: boolean, isValidating: boolean; } => {
  const { data, error, isLoading, isValidating } = useSWR(
    tradeId ? `blotter-trade-details-${tradeId}` : null,
    () => BlotterDataApi.getTradeDetails(tradeId!),
    {
      revalidateOnFocus: false
    }
  );

  return { data, error, isLoading, isValidating };
};

export const useSaveTrade = (): { trigger: (arg: TradeDetailsRequest) => Promise<TradeDetailsResponse | undefined>; isMutating: boolean; error?: AxiosError; } => {
  const { cache } = useSWRConfig();

  const { trigger, isMutating, error } = useSWRMutation<TradeDetailsResponse, AxiosError, Key, TradeDetailsRequest>(
    'blotter-save-trade',
    BlotterDataApi.saveTrade,
    {
      onSuccess(data) {
        const state = cache.get(TRADES_DATA_KEY) as State<TradesDataResponse[]>;
        const trades = state?.data;
        const itemMappedToTradesData: TradesDataResponse = BlotterDataApi.mapItemToTradeGridData(data);

        if (!trades) {
          cache.set(TRADES_DATA_KEY, { ...state, data: [itemMappedToTradesData] });
          return;
        }

        const index = trades.findIndex(t => t.id === data.id);
        const newData = replaceItemAt(trades, itemMappedToTradesData, index, false);

        cache.set(TRADES_DATA_KEY, { ...state, data: newData });

        mutate(TRADES_DATA_KEY, newData, { revalidate: false });
      },
    }
  );

  return { trigger, isMutating, error };
};

export const useDeleteTrade = () => {
  const { cache } = useSWRConfig();

  const { trigger, error, isMutating } = useSWRMutation<string, AxiosError, Key, string>(
    'delete-trade',
    BlotterDataApi.deleteTrade,
    {
      onSuccess(removedTradeId) {
        
				// TODO - explore if this is needed any more... suspect not. . .	
				
				const state = cache.get(TRADES_DATA_KEY) as State<TradesDataResponse[]>;
        const trades = state?.data;

        if (!trades) {
          return;
        }

        const index = trades.findIndex(t => t.id === removedTradeId);

        if (index > -1) {
          const newData = removeItemAt(trades, index);

          cache.set(TRADES_DATA_KEY, { ...state, data: newData });

          mutate(TRADES_DATA_KEY, newData, { revalidate: false });
        }
      },
    }
  );

	const deleteTrade: (id: string) => Promise<string | undefined> = trigger;

  return { deleteTrade, error, isMutating };
};


export const useExportTrades = (): { trigger: (arg: ExportTradesPayload) => Promise<Blob | undefined>; error?: AxiosError, isMutating: boolean; } => {
  const { trigger, error, isMutating } = useSWRMutation<Blob, AxiosError, Key, ExportTradesPayload>(
    'export-trades',
    BlotterDataApi.exportTrades
  );

  return { trigger, error, isMutating };
}