import useSWR, { Key } from 'swr';
import useSWRMutation from 'swr/mutation';

import { asEncoded } from 'helpers/Utils/string';

import { ShippingFixturesService } from './FixturesService';

import type { AxiosError } from 'axios';
import type { apiGetFixtureSummaries, apiGetSanctionSearchHistory } from '../Models/apiRequest';
import type { Snapshot } from '../Models/Snapshots';
import type { SanctionSearchHistoryResult } from '../Models/SanctionSearchHistory';


/**
 * Custom hook to load chunked shipping fixture data based on a passed
 * sert of search queries
 *
 * @param {apiGetFixtureSummaries} params
 * @returns {{ data: any; error: any; isLoading: any; mutate: any; }}
 */
export const useShippingFixtures = (params: apiGetFixtureSummaries) => {

	const cachekey: string = `shipping-fixtures.${asEncoded(params)}`;

	const { data, error, isLoading, mutate} = useSWR(
		params ? cachekey : null,
		() => ShippingFixturesService.getFixtures(params),
		{ revalidateOnFocus: false }
	)

	return { data, error, isLoading, mutate }

}

export const useFixtureSanctionDetails = (fixtureid: string) => {

	const { data, error, isLoading } = useSWR(
		fixtureid ? `fixture-sanction-${fixtureid}` : null,
		() => ShippingFixturesService.getFixtureDetails(fixtureid),
		{ revalidateOnFocus: false }
	)

	return { data, error, isLoading }

}

type SanctionSearchSanpshotReturnType = {
	data: Snapshot | undefined;
	isLoading: boolean;
	error?: AxiosError;
}

export const useSanctionSearchSanpshot = (snaphotid: string, fixtureid: string | null, execute: boolean = true): SanctionSearchSanpshotReturnType => {

	const { data, error, isLoading } = useSWR(
		execute ? `snapshot-${snaphotid}-${fixtureid}` : null,
		() => ShippingFixturesService.getSnaphot(fixtureid, snaphotid),
		{ revalidateOnFocus: false }
	)

	return { data, error, isLoading }
}

type SanctionSearchHistoryReturnType = {
  trigger: (arg: apiGetSanctionSearchHistory) => Promise<SanctionSearchHistoryResult | undefined>;
	isMutating: boolean;
	error?: AxiosError;
};

export const useSanctionSearchHistory = (): SanctionSearchHistoryReturnType => {
  const { trigger, error, isMutating } = useSWRMutation<SanctionSearchHistoryResult, AxiosError, Key, apiGetSanctionSearchHistory>(
    'sanction-search-history',
    ShippingFixturesService.getSearchHistory,
  );

  return { trigger, error, isMutating };
};
