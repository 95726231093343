import { ReactElement, useEffect, useState } from 'react';
import { DataTable, type DataTableExpandedRows, type DataTableRowToggleEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { ReadableDate } from 'helpers/DataTable/Templates/ColumnTemplates';
import { ResultsModeEnum, SanctionTypeAPI, TrackingType } from 'modules/Sanctions/Models/Enums';
import { DETAILS_NO_SELECTED_ITEM_MESSAGE } from 'modules/Sanctions/Models/Consts';
import { useSanctionSearchHistory } from 'modules/ShippingFixtures/Services/hooks';
import BorealisBar from 'components/BorealisBar';

import RowExpansionTemplate from './Components';

import type {
  IndividualSanctionResponse,
  LegalEntitySanctionResponse,
  VesselSanctionResponse
} from 'modules/Sanctions/Models/SanctionedItems';
import type {
  SanctionSearchHistoryResult,
  SanctionSnapshotActivityHistory
} from 'modules/ShippingFixtures/Models/SanctionSearchHistory';

import './SanctionedItemSearchHistory.scss';

interface SanctionedItemSearchHistoryProps {
  selectedItem: VesselSanctionResponse | LegalEntitySanctionResponse | IndividualSanctionResponse | null;
  resultsMode: ResultsModeEnum;
}

const SanctionedItemSearchHistory = (props: SanctionedItemSearchHistoryProps): ReactElement => {
  const { selectedItem, resultsMode } = props;
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows>({});
  const [history, setHistory] = useState<SanctionSearchHistoryResult>();

  const { trigger: getSearchHistory, isMutating, error } = useSanctionSearchHistory();

  const onRowToggle = (e: DataTableRowToggleEvent): void => {
    setExpandedRows(e.data as DataTableExpandedRows);
  };

  useEffect(() => {
    if (selectedItem) {
      fetchSearchHistory();
    }
  }, [selectedItem]);

  const fetchSearchHistory = async (): Promise<void> => {
    if (!selectedItem) return;

    try {
      const providerNameProp = resultsMode === ResultsModeEnum.IndividualSanctions ? 'listName' : 'providerName';

      const response = await getSearchHistory({
        id: selectedItem.id,
        providerName: selectedItem[providerNameProp as keyof typeof selectedItem] as string,
        sanctionType: SanctionTypeAPI[ResultsModeEnum[resultsMode] as keyof typeof SanctionTypeAPI],
        trackingType: TrackingType.MarkAsChecked
      });

      setHistory(response);
    } catch (e) {
      console.log('Something went wrong', e);
    }
  };

  if (!selectedItem) {
    return <div>{DETAILS_NO_SELECTED_ITEM_MESSAGE}</div>;
  }

  if (error && !isMutating) {
    return <div><i className='iconoir-xmark icon--small icon--red' />Something went wrong...</div>;
  }

  if (isMutating) {
    return <div className='position--relative'><BorealisBar /></div>;
  }

  return <>
    {history && <summary className='sanction-search-history__summary'>
      <div>Total marked as checked (last 90 days)</div>
      <span>{history.totalMarkedAsChecked}</span><span>({history.totalRowClicks} row clicks)</span>
    </summary>}
    <DataTable
      className='sanction-search-history__datatable header--micro'
      value={history?.snapshotActivityHistory}
      loading={isMutating}
      dataKey='id'
      expandedRows={expandedRows}
      onRowToggle={onRowToggle}
      scrollable
      scrollHeight='flex'
      pt={{
        headerRow: { className: 'sanction-search-history__datatable-row' },
        bodyRow: { className: 'sanction-search-history__datatable-row' }
      }}
      rowExpansionTemplate={(data) => <RowExpansionTemplate data={data} />}
      emptyMessage='No previous searches found'
    >
      <Column
        header='Name'
        headerClassName='no-border'
        field='userName'
      />
      <Column
        field='detailsOpenedDateUtc'
        header='When'
        headerClassName='no-border'
        body={(data, config) => ReadableDate<SanctionSnapshotActivityHistory>(data, config, 'dd LLL yyyy, HH:mm:ss')}
      />
      <Column
        expander={true}
        body={(data, config) => <i
          onClick={config.expander?.onClick}
          className='iconoir-binocular icon--small' />
        }
      />
    </DataTable>

  </>;
};

export default SanctionedItemSearchHistory;