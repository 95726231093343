export enum ResultsModeEnum  {
    VesselSanctions = 0,
    LegalEntitySanctions = 1,
    IndividualSanctions = 2,
    Snapshot = 3
}

export enum SanctionTypeAPI {
    VesselSanctions = 1,
    LegalEntitySanctions,
    IndividualSanctions
}

export enum SanctionStatusEnum {
    Unknown = -1,
    Low = 0,
    Medium = 1,
    High = 2,
}

export enum AliasStrength {
    Good = 1,
    Poor
}

export enum NameType {
    PrimaryName = 1,
    PrimaryNameVariation,
    Alias
}

export enum NonLatinNameScriptType {
    Arabic = 1,
    Cyrillic,
    Greek
}


export enum ActiveDetailsTab {
    Visualisation,
    Details,
    Other,
    HistorySearch
}

export enum TrackingType {
    RowClick,
    MarkAsChecked
}

export {}